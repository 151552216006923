import _find from "lodash.find";

export enum CountriesThatRequireStates {
  UnitedStates = "us",
  China = "cn",
  Canada = "ca"
}

export enum Views {
  Forecast = "forecast",
  Map = "map",
  Dashboard = "dashboard",
  Historical = "historical"
}

export enum PlaceType {
  Station = "station",
  Location = "local"
}

export default class Path {
  path: (PlaceType | string)[];
  view: Views | string;
  constructor(config) {
    this.view = config.view;
    this.type = config.type;
    this.place = config.place;
  }
  get activeUrl() {
    const subpath = window.location.pathname;
    const viewEntry = Object.keys(this.urls).filter(key => this.urls[key] === subpath)[0];
    return viewEntry;
  }
  get urls() {
    const suffix = this.place.map(item => encodeURIComponent(item)).join("/");
    return {
      [Views.Dashboard]: `/${this.type}/${suffix}`,
      [Views.Forecast]: `/${this.type}/${Views.Forecast}/${suffix}`,
      [Views.Historical]: `/${this.type}/${Views.Historical}/${suffix}`,
      [Views.Map]: `/${this.type}/${Views.Map}/${suffix}`
    };
  }
  get desktopUrls() {
    const suffix = this.place.map(item => encodeURIComponent(item)).join("/");
    return {
      [Views.Dashboard]: `/${this.type}/${suffix}`,
      [Views.Forecast]: `/${this.type}/${Views.Forecast}/${suffix}`,
      [Views.Map]: `/${this.type}/${Views.Map}/${suffix}`
    };
  }
  get mobileUrls() {
    const suffix = this.place.map(item => encodeURIComponent(item)).join("/");
    return {
      [Views.Dashboard]: `/${this.type}/${suffix}`,
      [Views.Forecast]: `/${this.type}/${Views.Forecast}/${suffix}`,
      [Views.Historical]: `/${this.type}/${Views.Historical}/${suffix}`,
      [Views.Map]: `/${this.type}/${Views.Map}/${suffix}`
    };
  }
}
