import theme from "@chakra-ui/core/dist/theme";
// import { createColorScale } from '../utils/colors';
import { createColorScale } from "pws-design-system/design-system/utils/colors";

const blacks = [
  "rgba(0,0,0,.0125)",
  "rgba(0,0,0,.025)",
  "rgba(0,0,0,.05)",
  "rgba(0,0,0,.1)",
  "rgba(0,0,0,.2)",
  "rgba(0,0,0,.3)",
  "rgba(0,0,0,.4)",
  "rgba(0,0,0,.5)",
  "rgba(0,0,0,.6)",
  "rgba(0,0,0,.7)",
  "rgba(0,0,0,.8)",
  "rgba(0,0,0,.9)"
];

const whites = [
  "rgba(255,255,255,.0125)",
  "rgba(255,255,255,.025)",
  "rgba(255,255,255,.05)",
  "rgba(255,255,255,.1)",
  "rgba(255,255,255,.2)",
  "rgba(255,255,255,.3)",
  "rgba(255,255,255,.4)",
  "rgba(255,255,255,.5)",
  "rgba(255,255,255,.6)",
  "rgba(255,255,255,.7)",
  "rgba(255,255,255,.8)",
  "rgba(255,255,255,.9)"
];

const brand: any = {
  green: {
    base: "#3aba50",
    levels: [
      "#e7f6ea",
      "#cdedd3",
      "#b1e3b9",
      "#90d89c",
      "#6aca7a",
      "#3aba4f",
      "#34a847",
      "#2d933e",
      "#267a33",
      "#1b5725"
    ]
  },
  blue: {
    base: "#0146e6",
    levels: [
      "#e6edfc",
      "#cbdaf9",
      "#acc4f6",
      "#87a9f3",
      "#5686ee",
      "#014ae6",
      "#0042cf",
      "#003ab4",
      "#002f94",
      "#002168"
    ]
  },
  lightBlue: {
    base: "#23bbf1",
    levels: [
      "#e5f7fd",
      "#c9eefb",
      "#a9e5f9",
      "#85daf7",
      "#59cdf4",
      "#23bdf1",
      "#1faada",
      "#1b96bf",
      "#177c9e",
      "#105972"
    ]
  },
  darkBlue: {
    base: "#0c496b",
    levels: [
      "#e7edf0",
      "#cddae0",
      "#afc3ce",
      "#8ba8b8",
      "#5c859b",
      "#0c4a6b",
      "#0a425f",
      "#093952",
      "#072e42",
      "#051f2c"
    ]
  },
  white: {
    base: "#fafbfd"
  },
  gray: {
    base: "hsl(211, 8%, 10%)",
    levels: [
      "hsl(209, 17%, 98%)",
      "hsl(210, 12%, 93%)",
      "hsl(206, 12%, 88%)",
      "hsl(211, 12%, 83%)",
      "hsl(211, 12%, 77%)",
      "hsl(209, 13%, 70%)",
      "hsl(212, 14%, 63%)",
      "hsl(212, 10%, 56%)",
      "hsl(211, 8%, 48%)",
      "hsl(213, 8%, 40%)",
      "hsl(211, 8%, 33%)",
      "hsl(214, 7%, 25%)",
      "hsl(211, 7%, 17%)",
      "hsl(217, 7%, 14%)"
    ]
  }
};
brand.primary = brand.green.base;

const keys = ["green", "blue", "lightBlue", "darkBlue", "gray"];
keys.forEach(key => {
  brand[key] = {
    ...brand[key],
    ...createColorScale(brand[key].levels)
  };
});

const text = {
  dark: {
    primary: "hsl(116, 0%, 20%)",
    secondary: "hsl(214, 5%, 36%)",
    tertiary: "hsl(206, 4%, 66%)"
  },
  light: {
    primary: "hsl(360, 0%, 95%)",
    secondary: "hsl(360, 0%, 75%)",
    tertiary: "hsl(360, 0%, 60%)"
  },
  error: "hsl(0, 74%, 49%)",
  warn: "hsl(48, 85%, 54%)",
  success: "hsl(145, 80%, 41%)"
};

const weather = {
  temps: {
    "-60": "#8f00ca",
    "-57.5": "#8400b5",
    "-55": "#7900a1",
    "-52.5": "#6e008c",
    "-50": "#630078",
    "-47.5": "#730080",
    "-45": "#840088",
    "-42.5": "#940090",
    "-40": "#a50099",
    "-37.5": "#b700a9",
    "-35": "#c900ba",
    "-32.5": "#db00cb",
    "-30": "#ed00dc",
    "-27.5": "#ef1ce4",
    "-25": "#f138ed",
    "-22.5": "#f354f6",
    "-20": "#f671ff",
    "-17.5": "#ef85fa",
    "-15": "#e999f6",
    "-12.5": "#e2adf1",
    "-10": "#dcc1ed",
    "-7.5": "#ab90d0",
    "-5": "#7b60b4",
    "-2.5": "#4b3097",
    0: "#1b007b",
    2.5: "#2f149c",
    5: "#4428bd",
    7.5: "#593cde",
    10: "#6e51ff",
    12.5: "#796aff",
    15: "#8483ff",
    17.5: "#8f9cff",
    20: "#9bb6ff",
    22.5: "#7ab1fe",
    25: "#59adfd",
    27.5: "#38a9fc",
    30: "#18a5fc",
    32.5: "#147bf6",
    35: "#1052f1",
    37.5: "#0c29ec",
    40: "#0900e7",
    42.5: "#0b2cad",
    45: "#0e5873",
    47.5: "#118439",
    50: "#14b000",
    52.5: "#4cb902",
    55: "#84c204",
    57.5: "#bccb06",
    60: "#f5d508",
    62.5: "#f0bc06",
    65: "#eba304",
    67.5: "#e68a02",
    70: "#e17200",
    72.5: "#e56200",
    75: "#ea5200",
    77.5: "#ee4200",
    80: "#f33300",
    82.5: "#ea2600",
    85: "#e11900",
    87.5: "#d80c00",
    90: "#d00000",
    92.5: "#b80000",
    95: "#a10000",
    97.5: "#890000",
    100: "#720000",
    102.5: "#890011",
    105: "#a00023",
    107.5: "#b70034",
    110: "#ce0046",
    112.5: "#da125a",
    115: "#e6256f",
    117.5: "#f23883",
    120: "#ff4b98"
  },
  winds: {
    0: "#0050ff",
    2: "#0050ff",
    2.5: "#007bff",
    4: "#1087ff",
    6: "#17b7fe",
    7.5: "#1bd3ff",
    10: "#34ff87",
    12.5: "#72ff09",
    15: "#b4ff08",
    17.5: "#efff0b",
    20: "#fee20a",
    22.5: "#f7cd0a",
    25: "#f0b90a",
    27.5: "#eea909",
    30: "#ed9909",
    32.5: "#f58f09",
    35: "#fd8609",
    37.5: "#fd7108",
    40: "#fd5d08",
    42.5: "#fc4a07",
    45: "#fb3706",
    47.5: "#fb3410",
    50: "#fb311b",
    52.5: "#fb2f21",
    55: "#fb2e28",
    57.5: "#fb2c2f",
    60: "#fb2b36",
    62.5: "#fb333d",
    65: "#fb3c44",
    67.5: "#fb444b",
    70: "#fb4d53",
    72.5: "#fb555b",
    75: "#fc5e63",
    77.5: "#fc676b",
    80: "#fd7074",
    82.5: "#fa7579",
    85: "#f87b7e",
    87.5: "#f68083",
    90: "#f48688",
    92.5: "#f28b8d",
    95: "#f09092",
    97.5: "#ee9597",
    100: "#ec9a9c"
  },
  windGusts: {
    0: "#0050ff",
    2: "#0050ff",
    2.5: "#007bff",
    4: "#1087ff",
    6: "#17b7fe",
    7.5: "#1bd3ff",
    10: "#34ff87",
    12.5: "#72ff09",
    15: "#b4ff08",
    17.5: "#efff0b",
    20: "#fee20a",
    22.5: "#f7cd0a",
    25: "#f0b90a",
    27.5: "#eea909",
    30: "#ed9909",
    32.5: "#f58f09",
    35: "#fd8609",
    37.5: "#fd7108",
    40: "#fd5d08",
    42.5: "#fc4a07",
    45: "#fb3706",
    47.5: "#fb3410",
    50: "#fb311b",
    52.5: "#fb2f21",
    55: "#fb2e28",
    57.5: "#fb2c2f",
    60: "#fb2b36",
    62.5: "#fb333d",
    65: "#fb3c44",
    67.5: "#fb444b",
    70: "#fb4d53",
    72.5: "#fb555b",
    75: "#fc5e63",
    77.5: "#fc676b",
    80: "#fd7074",
    82.5: "#fa7579",
    85: "#f87b7e",
    87.5: "#f68083",
    90: "#f48688",
    92.5: "#f28b8d",
    95: "#f09092",
    97.5: "#ee9597",
    100: "#ec9a9c"
  },
  dewpt: {
    "-40": "#5943ac",
    "-35": "#574eb4",
    "-30": "#5559bc",
    "-25": "#7177d2",
    "-20": "#8d95e8",
    "-15": "#aab4fe",
    "-10": "#8d94c5",
    "-5": "#71748d",
    0: "#555555",
    5: "#5e5851",
    10: "#675b4d",
    15: "#705e49",
    20: "#7b6652",
    25: "#866f5b",
    30: "#927865",
    35: "#9b8a7d",
    40: "#a59c96",
    45: "#afafaf",
    50: "#5ee243",
    55: "#39ca21",
    60: "#14b300",
    65: "#118800",
    70: "#0e5e00",
    75: "#508200",
    80: "#93a600",
    85: "#c9cf00",
    90: "#fff800"
  },
  humidity: {
    0: "#4b2b18",
    5: "#573a29",
    10: "#634a3b",
    15: "#70594c",
    20: "#7c695e",
    25: "#89796f",
    30: "#958881",
    35: "#a19892",
    40: "#aea7a4",
    45: "#bab7b5",
    50: "#c7c7c7",
    55: "#98d88e",
    60: "#6aea55",
    65: "#41de2a",
    70: "#18d200",
    75: "#16be00",
    80: "#14ab00",
    85: "#139800",
    90: "#118400",
    95: "#0f7100",
    100: "#0e5e00"
  },
  pressure: {
    950: "#d08aff",
    955: "#b545ff",
    960: "#9a00ff",
    965: "#7e00ff",
    970: "#6300ff",
    975: "#3100db",
    980: "#0000b8",
    985: "#0425c9",
    990: "#084ada",
    995: "#048bec",
    1000: "#00cdff",
    1005: "#11d47f",
    1010: "#22dc00",
    1015: "#8fea05",
    1020: "#fdf90a",
    1025: "#fdba05",
    1030: "#fe7b00",
    1035: "#fc3d04",
    1040: "#fb0008",
    1045: "#c90004",
    1050: "#970000",
    1055: "#b92e2f",
    1060: "#db5c5f"
  },
  precip: {
    0.0: "#666",
    0.01: "#deffd6",
    0.1: "#bbffab",
    0.25: "#9af480",
    0.5: "#7aea55",
    0.75: "#59df2a",
    1: "#39d500",
    1.25: "#2fb700",
    1.5: "#259901",
    1.75: "#1b7b02",
    2: "#125d03",
    2.25: "#4d8506",
    2.5: "#88ae09",
    2.75: "#c3d60c",
    3: "#ffff0f",
    3.25: "#fed90b",
    3.5: "#feb407",
    3.75: "#fd8f03",
    4: "#fd6a00",
    4.25: "#f95c00",
    4.5: "#f54f00",
    4.75: "#f14200",
    5: "#ee3500",
    5.25: "#ea2700",
    5.5: "#e61a00",
    5.75: "#e20d00",
    6: "#df0000",
    6.25: "#d20000",
    6.5: "#c60000",
    6.75: "#b90000",
    7: "#ad0000",
    7.25: "#a00000",
    7.5: "#940000",
    7.75: "#870000",
    8: "#7b0000",
    8.25: "#88001c",
    8.5: "#960038",
    8.75: "#a30054",
    9: "#b10070",
    9.25: "#bf008c",
    9.5: "#cc00a8",
    9.75: "#da00c4",
    10: "#e800e0"
  },
  snow: {
    0.01: "#dadada",
    0.25: "#bbbbbb",
    0.5: "#ababab",
    0.75: "#9b9b9b",
    1: "#baebff",
    5: "#4cb2ff",
    6: "#3e82ff",
    8.5: "#255ae5",
    11: "#0c32cc",
    12: "#4e00c3",
    15: "#7700b6",
    18: "#a100a9",
    21: "#ca009c",
    24: "#f40090"
  }
};

export const colors: { [key: string]: string | any } = {
  // eslint-disable-line import/prefer-default-export
  ...theme.colors,
  transparent: "transparent",
  blacks,
  whites,
  brand,
  text,
  bg: {
    light: brand.white.base,
    dark: brand.gray.base
  },
  weather,
  red: createColorScale(
    [
      "#810000",
      "#990000",
      "#b30000",
      "#c51f1f",
      "#ce3f3f",
      "#d65858",
      "#dc6e6e",
      "#e38383",
      "#e99797",
      "#efabab"
    ].reverse()
  ),
  green: createColorScale([
    "#c2e6bb",
    "#a0dc95",
    "#7cd16c",
    "#55c540",
    "#28b80e",
    "#1aa700",
    "#189700",
    "#158600",
    "#137700",
    "#116700"
  ])
};
