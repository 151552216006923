import React from "react";
import { defaultTheme, CircularMeter } from "pws-design-system/design-system";
import { colorForValue } from "pws-design-system/design-system/utils/colors";
import MeterProps from "./MeterProps";
import _isNil from "lodash.isnil";
import { UnitStoreContainer } from "../../../../../../common/hooks/stores/useUnitStore";
import { UnitTypes, UnitCategory } from "../../../../../../../types/enums";
import { config } from "../../../../../../../models/aeris/observation/DataFormatter";
import { css } from "@emotion/react";
import { ThemeContainer } from "../../../../../../common/hooks/useTheme";
import { PressureTypeContainer } from "../../../../../../common/hooks/stores/usePressureTypeStore";

function PressureMeter({ observation, animationDelay = 0, ...rest }: MeterProps) {
  const { theme } = ThemeContainer.useContainer();
  const { unitStore } = UnitStoreContainer.useContainer();
  const { pressureType } = PressureTypeContainer.useContainer();

  const pressureTypeMap = {
    altimeter: "altimeter",
    station: "spressure",
    mslp: "pressure"
  };

  const unitType = unitStore[UnitCategory.Pressure];
  const value = observation.pressureIn;
  const labelValue = observation[pressureTypeMap[pressureType]];

  const formatter = {
    [UnitTypes.Imperial]: (value: number) =>
      Number(value).toFixed(config[UnitCategory.Pressure][unitType].decimals),
    [UnitTypes.Metric]: (value: number) =>
      Number(value).toFixed(config[UnitCategory.Pressure][unitType].decimals)
  };

  return (
    <CircularMeter
      variant={theme.name}
      disabled={_isNil(value) === true}
      label="Pressure"
      range={{ min: 27, max: 32 }}
      value={value}
      labelValue={labelValue}
      css={css`
        div:nth-of-type(3) {
          white-space: pre;
          bottom: 25px;
          text-align: center;
        }
      `}
      labelStyles={{
        whiteSpace: "pre",
        bottom: "-25px",
        textAlign: "center"
      }}
      color={[
        "#666",
        colorForValue(defaultTheme.colors.weather.pressure, value * 33.86389) || "#666"
      ]}
      units={`${observation.pressureUnits}
(${pressureType})`}
      formatter={formatter[unitType]}
      animation={{ delay: animationDelay }}
      bgColor={theme.components.meter.bg}
      disabledBgColor={theme.components.meter.disabledBgColor}
      {...rest}
    />
  );
}

export default PressureMeter;
