import "@aerisweather/javascript-sdk/dist/styles/styles.css";
import { Box } from "pws-design-system/design-system";
import React, { useLayoutEffect, useRef } from "react";
import uuid from "uuid/v4";
import aeris from "../../../../api";

enum INTERACTIVE_MAP {
  MAP = "InteractiveMap",
  APP = "InteractiveMapApp"
}

type MapProps = any & {
  width?: any;
  height?: any;
};

export default function Map({
  type = INTERACTIVE_MAP.MAP,
  width = "100%",
  height = "100%",
  config,
  mapReadyHandler
}: MapProps) {
  const uid = useRef(uuid());
  const mapEl = useRef(null);

  useLayoutEffect(() => {
    async function initMap() {
      if (type === INTERACTIVE_MAP.MAP) {
        const views = await aeris.views();
        const view = new views.InteractiveMap(`#${mapEl.current.id}`, config);
        view.on("ready", () => mapReadyHandler(view));
      }
      if (type === INTERACTIVE_MAP.APP) {
        const apps = await aeris.apps();
        const app = new apps.InteractiveMapApp(`#${mapEl.current.id}`, config);
        app.map.on("ready", () => mapReadyHandler(app.map, app));
      }
    }
    initMap();
  }, []);

  return (
    <Box width={width} height={height}>
      <div style={{ width: "100%", height: "100%" }} ref={mapEl} id={uid.current} />
    </Box>
  );
}
