import { navigate } from "gatsby";
import _get from "lodash.get";
import {
  Absolute,
  Box,
  Button,
  Flex,
  Hide,
  IconButton,
  Link,
  Stack,
  Stat,
  Text,
  WeatherIcon
} from "pws-design-system/design-system";
import React from "react";
import Observation from "../../../../models/aeris/observation";
import { WindMeter } from "../../../pages/place/dashboard/cards/latest-observation/meters";
import useDailySummaryQuery from "../../hooks/query/useDailySummaryQuery";
import { ThemeContainer } from "../../hooks/useTheme";
import { PressureTypeContainer } from "../../hooks/stores/usePressureTypeStore";
import { Link as ReachLink } from "gatsby";

interface MapPanelProps {
  variant?: "normal" | "minimal";
  observation: Observation;
  url: string;
  handleCloseClick: () => void;
}

export default function MapPanel({
  variant,
  observation,
  url,
  handleCloseClick
}: MapPanelProps): React.ReactElement {
  const { theme } = ThemeContainer.useContainer();
  const { pressureType } = PressureTypeContainer.useContainer();
  const { summary } = useDailySummaryQuery(observation.data.id);
  if (Array.isArray(observation.data)) {
    return (
      <Absolute
        top={["10px", null, "20px"]}
        left={["10px", null, "20px"]}
        right={["10px", null, "auto"]}
        zIndex={1}
      >
        <Box
          width={[null, null, "400px"]}
          boxShadow="lg"
          bg={theme.components.map.panel.bg}
          color={theme.components.map.panel.color}
          rounded={["lg", null, null, "xl"]}
        >
          <Box p={[3, null, 4]}>
            <Flex justify="space-between">
              <Text variant="subheadline">No Data</Text>

              <IconButton
                color="brand.gray.400"
                size="md"
                variant="ghost"
                mt={-3}
                mr={-3}
                icon="close"
                aria-label="Close Panel"
                onClick={handleCloseClick}
              />
            </Flex>
            <Text variant="caption">
              This station has not sent us data recently. If this is your personal weather station,{" "}
              <Link
                to="/frequently-asked-questions#PWS%20Setup"
                as={ReachLink}
                variant="light"
                color="brand.primary"
              >
                read our docs on how to setup your PWS
              </Link>
              .
            </Text>
          </Box>
        </Box>
      </Absolute>
    );
  }
  if (variant === "minimal") {
    return (
      <Absolute top="10px" left="10px" right="10px" zIndex={1}>
        <Box boxShadow="lg" bg={theme.components.map.panel.bg} rounded={["lg", null, null, "xl"]}>
          <Box p={3}>
            <Flex justify="space-between">
              <Text variant="subheadline" color={theme.components.map.panel.color}>
                {observation.displayId.toUpperCase()}
              </Text>
              <IconButton
                color="brand.gray.400"
                size="md"
                variant="ghost"
                mt={-3}
                mr={-3}
                icon="close"
                aria-label="Close Panel"
                onClick={handleCloseClick}
              />
            </Flex>
            {observation.has("date") === true && (
              <Text variant="caption2" color="brand.gray.700" mt={-2}>
                {observation.dateDistanceToNowInWords}
              </Text>
            )}
            <Flex justify="space-between" align="center">
              <Box>
                <Flex align="center">
                  {observation.has("weatherShortCode") && (
                    <WeatherIcon
                      key={observation.weatherShortCode}
                      code={observation.weatherShortCode}
                      width="40px"
                      height="40px"
                      mr={3}
                    />
                  )}
                  <Stack mt={2} width="80px">
                    <Stack height="22px" align="baseline" spacing={"3px"} isInline>
                      <Text variant="value" fontSize="3xl">
                        {observation.has("tempFormatted") ? observation.tempFormatted : "--"}
                      </Text>
                      <Text fontSize="lg" height="22px">
                        {observation.tempUnits}
                      </Text>
                    </Stack>
                    <Hide mobile>
                      <Text variant="callout">{observation.weatherPrimary}</Text>
                    </Hide>
                  </Stack>
                </Flex>
                <Hide tablet desktop>
                  <Text mt={1} variant="callout">
                    {observation.weatherPrimary}
                  </Text>
                </Hide>
              </Box>
              <WindMeter size="sm" observation={observation} />
              <IconButton
                bg="brand.green.base"
                color="brand.white.base"
                size="lg"
                icon="arrow-right"
                aria-label="View Station Details"
                rounded="full"
                onClick={() => window.location.assign(url)}
              />
            </Flex>
          </Box>
        </Box>
      </Absolute>
    );
  }

  return (
    <Absolute
      top={["10px", null, "20px"]}
      left={["10px", null, "20px"]}
      right={["10px", null, "auto"]}
      zIndex={1}
    >
      <Box
        width={[null, null, "400px"]}
        boxShadow="lg"
        bg={theme.components.map.panel.bg}
        color={theme.components.map.panel.color}
        rounded={["lg", null, null, "xl"]}
      >
        <Box p={[3, null, 4]}>
          <Flex justify="space-between">
            <Text variant="subheadline">{observation.displayId.toUpperCase()}</Text>
            <IconButton
              color="brand.gray.400"
              size="md"
              variant="ghost"
              mt={-3}
              mr={-3}
              icon="close"
              aria-label="Close Panel"
              onClick={handleCloseClick}
            />
          </Flex>
          {observation.has("date") === true && (
            <Text
              variant="caption2"
              color={theme.components.map.panel.date.color}
              mt={[-2, null, 0]}
            >
              {observation.dateDistanceToNowInWords}
            </Text>
          )}
          <Flex justify="space-between" align="center">
            <Box>
              <Flex align="center">
                {observation.has("weatherShortCode") && (
                  <WeatherIcon
                    key={observation.weatherShortCode}
                    code={observation.weatherShortCode}
                    width={["40px", null, "60px"]}
                    height={["40px", null, "60px"]}
                    mr={3}
                  />
                )}
                <Stack mt={2} width={["80px", null, "auto"]}>
                  <Stack height={["22px", null, "36px"]} align="baseline" spacing={"3px"} isInline>
                    <Text variant="value" fontSize={["3xl", null, "5xl"]}>
                      {observation.has("tempFormatted") ? observation.tempFormatted : "--"}
                    </Text>
                    <Text fontSize={["lg", null, "xl"]} height={["22px", null, "36px"]}>
                      {observation.tempUnits}
                    </Text>
                  </Stack>
                  <Hide mobile>
                    <Text variant="callout">{observation.weatherPrimary}</Text>
                  </Hide>
                </Stack>
              </Flex>
              <Hide tablet desktop>
                <Text mt={1} variant="callout">
                  {observation.weatherPrimary}
                </Text>
              </Hide>
            </Box>
            <WindMeter size="sm" observation={observation} />
            <Hide tablet desktop>
              <IconButton
                bg="brand.green.base"
                color="brand.white.base"
                size="lg"
                icon="arrow-right"
                aria-label="View Station Details"
                rounded="full"
                onClick={() => window.location.assign(url)}
              />
            </Hide>
          </Flex>
          <Hide mobile>
            <Stack mt={[3, null, 4]} mb={4} spacing={4} isInline>
              <Stat
                size="sm"
                icon="humidity"
                value={observation.has("humidityFormatted") ? observation.humidityFormatted : "--"}
                label="Humidity"
                units="%"
              />
              <Stat
                size="sm"
                icon="pressure"
                value={observation.has("pressureByType") ? observation.pressureByType : "--"}
                label="Pressure"
                units={observation.pressureUnits}
              />
              {summary.hasData && (
                <Stat
                  size="sm"
                  icon="precip"
                  value={
                    (_get(summary, "rawData.data.profile.hasPrecip", false) &&
                      summary.precipTotalFormatted) ||
                    "--"
                  }
                  label="Precip Today"
                  units={observation.precipUnits}
                />
              )}
            </Stack>
            <Button width="100%" variantColor="brand" onClick={() => window.location.assign(url)}>
              Station Dashboard
            </Button>
          </Hide>
        </Box>
      </Box>
    </Absolute>
  );
}
