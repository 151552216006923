import React, { useState, useEffect } from "react";

type ReactChildren = React.ReactElement | React.ReactElement[];
// Waits for a specified delay before rendering its children.
// Useful for delaying loading indicators to make UI feel
// snappier.
interface DelayedRenderProps {
  // Milliseconds to delay
  delay?: number;
  // children to render
  children: React.ReactNode;
}

export default function DelayedRender({ delay = 300, children }: DelayedRenderProps) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timeoutHandle = setTimeout(() => setShow(true), delay);
    return () => clearTimeout(timeoutHandle);
  }, []);
  if (show === false) {
    return null;
  }
  return <>{children}</>;
}
