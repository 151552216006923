import _isEqual from "lodash.isequal";
import React from "react";

/**
 * If non-primitives are deepequal, the current
 * ref is not changed. This allows non primitives
 * to trigger useEffect callback only when their
 * properties have changed.
 *
 * @param {*} value
 * @returns {*} current ref value
 */
function useDeepEqualMemoize(value) {
  const ref = React.useRef();
  if (!_isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export default useDeepEqualMemoize;
