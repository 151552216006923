import React from "react";
import { defaultTheme, CircularMeter } from "pws-design-system/design-system";
import { colorForValue } from "pws-design-system/design-system/utils/colors";
import { bezier } from "pws-design-system/design-system/utils/bezier";
import MeterProps from "./MeterProps";
import _isNil from "lodash.isnil";
import { ThemeContainer } from "../../../../../../common/hooks/useTheme";

function TemperatureMeter({ observation, animationDelay = 0, ...rest }: MeterProps) {
  const value = observation.tempF;
  const labelValue = observation.temp;
  const { theme } = ThemeContainer.useContainer();

  return (
    <CircularMeter
      variant={theme.name}
      animation={{ delay: animationDelay }}
      color={["#666", colorForValue(defaultTheme.colors.weather.temps, value) || "#666"]}
      converter={progress => bezier(0.4, 0, 0.8, 1)(progress)}
      disabled={_isNil(value) === true}
      formatter={labelValue => Number(labelValue).toFixed(1)}
      label="Temperature"
      range={{ min: -30, max: 110 }}
      units={observation.tempUnits}
      value={value}
      labelValue={labelValue}
      bgColor={theme.components.meter.bg}
      disabledBgColor={theme.components.meter.disabledBgColor}
      {...rest}
    />
  );
}

export default TemperatureMeter;
