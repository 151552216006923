import { Views, PlaceType, StationTypePrefixes } from "../../types/enums";
import Station from "./Station";
import Location from "./Place";

function startsWithASubview(segments) {
  return Object.values(Views).includes(segments[1]);
}

export default function pathFactory(path: string) {
  const segments = path.split("/").filter((segment: string) => segment !== "" && segment !== "/");

  const prefixes = {
    mid: "mid_",
    aus: "aus_",
    pws: "pws_"
  };

  const config = {};
  if (startsWithASubview(segments)) {
    config.view = segments[1];
    if (Object.values(StationTypePrefixes).includes(segments[2])) {
      const stationType = segments[2];
      const placeId = segments.slice(3);
      const prefix = prefixes[stationType];
      config.place = [`${prefix}${placeId}`];
    } else {
      config.place = segments.slice(2);
    }
  } else {
    config.view = Views.Dashboard;
    if (Object.values(StationTypePrefixes).includes(segments[1])) {
      const stationType = segments[1];
      const placeId = segments.slice(2);
      const prefix = prefixes[stationType];
      config.place = [`${prefix}${placeId}`];
    } else {
      config.place = segments.slice(1);
    }
  }
  if (segments[0] === PlaceType.Station) {
    config.type = PlaceType.Station;
    const station = new Station(config);
    return new Station(config);
  }

  if (segments[0] === PlaceType.Location) {
    config.type = PlaceType.Location;
    return new Location(config);
  }

  console.error(path, segments);
}

export function buildStationPath(view = "dashboard", id: string) {
  const prefixes = {
    aus_: "aus",
    mid_: "mid",
    pws_: "pws"
  };
  const views = {
    dashboard: "",
    forecast: "forecast/",
    historical: "historical/",
    map: "map/"
  };
  let queryId = id;
  let type = "";
  const prefix = Object.keys(prefixes)
    .filter(key => id.toLowerCase().startsWith(key))
    .map(key => prefixes[key]);

  if (prefix.length !== 0) {
    queryId = id.slice(4);
    type = `${prefix[0]}/`;
  }
  return `/station/${views[view]}${type}${queryId.toLowerCase()}`;
}

export function buildLocationPath(view = "dashboard", id: string) {
  return `${view}${id}`;
}
