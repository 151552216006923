import { useMachine } from "@xstate/react";
import { Machine, State } from "xstate";

export type FetchState = State<any>;

export enum FetchEvent {
  LOADING = "LOADING",
  LOADED = "LOADED",
  NO_DATA = "NO_DATA",
  ERROR = "ERROR",
  RESET = "RESET"
}

const machine = Machine({
  id: "fetchMachine",
  initial: "start",
  states: {
    start: {
      id: "start",
      on: { LOADING: "initialLoad" }
    },
    initialLoad: {
      on: {
        LOADED: "initialLoadComplete",
        NO_DATA: "noData",
        ERROR: "error",
        RESET: "#start"
      }
    },
    error: {
      id: "error",
      on: { LOADING: "initialLoad", RESET: "#start" }
    },
    noData: {
      id: "noData",
      on: { LOADING: "initialLoad", RESET: "#start" }
    },
    initialLoadComplete: {
      initial: "loaded",
      states: {
        loading: {
          on: {
            LOADED: "loaded",
            ERROR: "#error",
            NO_DATA: "#noData",
            RESET: "#start"
          }
        },
        loaded: {
          on: { LOADING: "loading", RESET: "#start" }
        }
      }
    }
  }
});

export default function useFetchStateMachine(): [FetchState, (event: string) => void] {
  const [current, send] = useMachine(machine);
  return [current, send];
}
