import React from "react";
import { Button, Stack, Text } from "pws-design-system/design-system";
import { SegmentedControl } from "../../components/segmented-control";

type UnitOptionProps = {
  type: string;
  label: string;
  options: { value: string; label: string }[];
  value?: string;
  context: string;
  changeHandler?: (type: string, value: string) => void;
  optionWidth?: string;
};

const UnitOption = ({
  type,
  label,
  options,
  value,
  changeHandler,
  optionWidth = "130px",
  context,
  ...rest
}: UnitOptionProps): React.ReactElement => {
  const handleClick = (type: string) => {
    console.log("!!!!!Work Damnit ", type);
  };

  return (
    <Stack key={type} justify="space-between" align="center" isInline {...rest}>
      <Text
        fontFamily="label"
        fontSize="xs"
        fontWeight="semibold"
        textTransform="uppercase"
        letterSpacing="wider"
      >
        {label}
      </Text>
      <SegmentedControl
        value={value}
        width={optionWidth}
        context={context}
        onChange={(val: string) => (changeHandler ? changeHandler(type, val) : null)}
      >
        {options.map(option => (
          <button
            key={`${type}-${option.value}`}
            value={option.value}
            onClick={() => handleClick(option.label)}
          >
            {option.label}
          </button>
          // <Button key={`${type}-${option.value}`} value={option.value} onClick={() => handleClick(option.label)}>
          // {option.label}
          // </Button>
        ))}
      </SegmentedControl>
    </Stack>
  );
};

export default UnitOption;
