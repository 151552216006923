import _isNil from "lodash.isnil";
import { useEffect, useState } from "react";
import aeris from "../../../../api";
import Model from "../../../../models/Model";
import Response from "../../../../models/aeris/response/Response";
import useFetchStateMachine from "../useFetchStateMachine";
import useDeepEqualMemoize from "../util/useDeepEqualMemoize";

/**
 * A default hook which queries the api through the JS SDK
 * and builds a value object and returns a fetch state
 * machine.  Use for consistent querying logic.
 *
 * @param {Object} apiMethods key value pairs which align
 * with JS SDK ApiRequest methods
 * @param {Model} Model value object to build from SDK response
 * @param {Response} ResponseModel to build from raw SDK response
 * @param {number} heartbeatInterval in milliseconds to repeatedly
 * fetch on
 * @param {function} postFetchHook to run if custom logic is needed
 * to build the value object
 * @returns {Object} value object and fetch state
 */
const useQuery = (
  apiMethods,
  Model: any,
  ResponseModel = Response,
  heartbeatInterval?: number,
  postFetchHook
) => {
  const [fetchState, sendFetchEvent] = useFetchStateMachine();
  const [model, setModel] = useState(new Model());
  const api = aeris.api();

  const postFetchHookHandler = (Model: Model, response: Response) => {
    if (postFetchHook) {
      postFetchHook(Model, response, setModel);
    } else {
      setModel(new Model({ data: response.data, rawData: response }));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        Object.keys(apiMethods).forEach(key => {
          const value = apiMethods[key];
          api[key](value);
        });
        sendFetchEvent("LOADING");
        const request = await api.get();
        const response = new Response(request);

        if (response.hasData === false) {
          sendFetchEvent("NO_DATA");
          return;
        }
        if (response.hasError) {
          sendFetchEvent("ERROR");
          return;
        }
        postFetchHookHandler(Model, response);
        sendFetchEvent("LOADED");
      } catch (e) {
        sendFetchEvent("ERROR");
      }
    };
    fetchData();
    let heartbeatHandle;
    if (_isNil(heartbeatInterval) !== true) {
      heartbeatHandle = window.setInterval(fetchData, heartbeatInterval);
    }
    return () => window.clearInterval(heartbeatHandle);
  }, [useDeepEqualMemoize(apiMethods)]);

  return { model, fetchState };
};

export default useQuery;
