import _isNil from "lodash.isnil";
import _has from "lodash.has";
import _get from "lodash.get";
import { UnitCategory, Units, UnitTypes } from "../../../types/enums";

export const config = {
  [UnitCategory.Temp]: {
    separator: "",
    [UnitTypes.Metric]: {
      units: Units.Celsius,
      decimals: 1
    },
    [UnitTypes.Imperial]: {
      units: Units.Fahrenheit,
      decimals: 1
    }
  },
  [UnitCategory.Speed]: {
    separator: " ",
    [UnitTypes.Metric]: {
      units: Units.KilometersPerhour,
      decimals: 1
    },
    [UnitTypes.Imperial]: {
      units: Units.MilesPerHour,
      decimals: 1
    },
    [UnitTypes.Custom]: {
      units: Units.Knots,
      decimals: 1
    }
  },
  [UnitCategory.Distance]: {
    separator: "",
    [UnitTypes.Metric]: {
      units: Units.KilometersPerhour,
      decimals: 0
    },
    [UnitTypes.Imperial]: {
      units: Units.Miles,
      decimals: 0
    }
  },
  [UnitCategory.Height]: {
    separator: " ",
    [UnitTypes.Metric]: {
      units: Units.Meters,
      decimals: 0
    },
    [UnitTypes.Imperial]: {
      units: Units.Feet,
      decimals: 0
    }
  },
  [UnitCategory.Pressure]: {
    separator: " ",
    [UnitTypes.Metric]: {
      units: Units.Millibars,
      decimals: 1
    },
    [UnitTypes.Imperial]: {
      units: Units.Inches,
      decimals: 2
    }
  },
  [UnitCategory.Percent]: {
    separator: "",
    [UnitTypes.Metric]: {
      units: Units.Percent,
      decimals: 0
    },
    [UnitTypes.Imperial]: {
      units: Units.Percent,
      decimals: 0
    }
  },
  [UnitCategory.Rain]: {
    separator: " ",
    [UnitTypes.Metric]: {
      units: Units.Millimeters,
      decimals: 1
    },
    [UnitTypes.Imperial]: {
      units: Units.Inches,
      decimals: 2
    }
  },
  [UnitCategory.Snow]: {
    separator: " ",
    [UnitTypes.Metric]: {
      units: Units.Centimeters,
      decimals: 1
    },
    [UnitTypes.Imperial]: {
      units: Units.Inches,
      decimals: 1
    }
  }
};

export default class DataFormatter {
  constructor(
    private unitCategory: UnitCategory,
    private unitType: UnitTypes,
    private value?: number
  ) {}

  private get separator() {
    return _get(config, `${this.unitCategory}.separator`, false);
  }

  public get units() {
    return _get(config, `${this.unitCategory}.${this.unitType}.units`, "");
  }

  private get decimal() {
    return _get(config, `${this.unitCategory}.${this.unitType}.decimals`, 0);
  }

  public get formattedValue(): string | number | null {
    if (_isNil(this.value)) {
      return this.value;
    }

    const decimal = this.decimal;

    if (decimal > 0) {
      return Number(this.value).toFixed(decimal);
    }

    return Math.round(this.value);
  }

  public get formattedValueWithUnits(): string | null {
    if (_isNil(this.value)) {
      return this.value;
    }

    return `${this.formattedValue}${this.separator}${this.units}`;
  }
}
