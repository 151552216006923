import { css } from "@emotion/react";
import _get from "lodash.get";
import { Box, Button, IconButton, Popover, Stack, Text } from "pws-design-system/design-system";
import React from "react";
import { units as unitsByType } from "../../../../models/aeris/units";
import { PressureType, ClockType, UnitCategory, UnitTypes } from "../../../../types/enums";
import { SegmentedControl } from "../../components/segmented-control";
import { PressureTypeContainer } from "../../hooks/stores/usePressureTypeStore";
import { TimeStoreContainer } from "../../hooks/stores/useTimeStore";
import { UnitStoreContainer } from "../../hooks/stores/useUnitStore";
import { ThemeContainer } from "../../hooks/useTheme";
import UnitOption from "./UnitOption";

export const Units = {
  metric: "metric",
  imperial: "imperial",
  custom: "custom"
};

const measureTitles: { [key: string]: string } = {
  temp: "Temp",
  speed: "Speed/Wind",
  distance: "Distance",
  height: "Height",
  pressure: "Pressure",
  rain: "Rainfall",
  snow: "Snowfall"
};

type UnitToggleProps = any & {
  variant?: "normal" | "dark";
  showSettings?: boolean;
};

const UnitToggle = ({
  size = "xs",
  variant,
  showSettings = true,
  context,
  ...rest
}: UnitToggleProps): React.ReactElement => {
  const { units, unitType, setUnit, setUnitType } = UnitStoreContainer.useContainer();
  const { timeFormat, setTimeFormat } = TimeStoreContainer.useContainer();
  const { pressureType, setPressureType } = PressureTypeContainer.useContainer();
  const { theme, setTheme } = ThemeContainer.useContainer();

  const popoverBg = _get(theme, `${context}.popover.bg`, null);
  const popoverColor = _get(theme, `${context}.popover.color`, null);
  const popoverSecondaryColor = _get(theme, `${context}.popover.secondaryColor`, null);
  const popoverTitleBorderColor = _get(theme, `${context}.popover.title.borderColor`, null);

  const handleChange = (val: UnitTypes) => {
    setUnitType(val);
  };

  const updateUnit = (type: UnitCategory, value: UnitTypes) => {
    setUnit(type, value);
  };

  if (showSettings === false) {
    return (
      <SegmentedControl
        defaultValue={unitType}
        value={unitType}
        onChange={handleChange}
        {...rest}
        context="components.dashboard.header.unitToggle"
      >
        <Button value={Units.imperial}>Imperial</Button>
        <Button value={Units.metric}>Metric</Button>
      </SegmentedControl>
    );
  }

  return (
    <Stack align="center" spacing={0} isInline>
      <Popover.Container placement="auto" closeOnBlur={true} returnFocusOnClose={false} usePortal>
        <Popover.Trigger>
          <IconButton
            height="20px"
            width="30px"
            minWidth="30px"
            variant="ghost"
            color="brand.gray.600"
            size="md"
            icon="settings"
            outline="none"
            aria-label="Unit Settings"
            css={css`
              :hover {
                background: transparent;
              }
            `}
          />
        </Popover.Trigger>
        <Popover.Content
          width="350px"
          border={0}
          borderRadius="xl"
          zIndex={500}
          bg={popoverBg}
          css={css`
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.04), 0 4px 16px 0 rgba(0, 0, 0, 0.16);
            border-radius: 0.75em;
            :focus {
              box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.04), 0 4px 16px 0 rgba(0, 0, 0, 0.16);
            }
          `}
        >
          <Popover.Arrow />
          <Popover.CloseButton />
          <Popover.Header mt={2} mb={1} borderColor={popoverTitleBorderColor}>
            <Text
              mb={-1}
              fontFamily="heading"
              fontSize="2xl"
              fontWeight="bold"
              color={popoverColor}
            >
              Unit Settings
            </Text>
          </Popover.Header>
          <Popover.Body p={3}>
            <SegmentedControl
              defaultValue={unitType}
              value={unitType}
              onChange={handleChange}
              context="components.dashboard.header.unitToggle"
            >
              <Button value={Units.imperial}>Imperial</Button>
              <Button value={Units.metric}>Metric</Button>
              <Button value={Units.custom}>Custom</Button>
            </SegmentedControl>
            <Box>
              <Text mt={3} mb={2} variant="caption2" color={popoverSecondaryColor}>
                Select the unit you want to display for each each measurement below.
              </Text>
              <Stack spacing={2}>
                {Object.keys(units)
                  .map(key => {
                    const label = measureTitles[key];
                    if (!label) {
                      return null;
                    }
                    //console.log('key label units', key, label, units[key], unitsByType[key][0]);
                    //console.log(key, label, units[key], unitsByType[key]);
                    return (
                      <Box color={popoverColor} key={`unit-option-box-${key}`}>
                        <UnitOption
                          type={key}
                          label={label}
                          context="components.dashboard.header.unitToggle"
                          value={units[key]}
                          changeHandler={(type: UnitCategory, val: UnitTypes) =>
                            updateUnit(type, val)
                          }
                          optionWidth={key === "speed" ? "200px" : "130px"}
                          options={
                            key === "speed"
                              ? [
                                  {
                                    value: unitsByType[key][1],
                                    label: unitsByType[key][1]
                                  },
                                  {
                                    value: unitsByType[key][0],
                                    label: unitsByType[key][0]
                                  },
                                  {
                                    value: unitsByType[key][2],
                                    label: unitsByType[key][2]
                                  },
                                  {
                                    value: unitsByType[key][3],
                                    label: unitsByType[key][3]
                                  }
                                ]
                              : [
                                  {
                                    value: unitsByType[key][1],
                                    label: unitsByType[key][1]
                                  },
                                  {
                                    value: unitsByType[key][0],
                                    label: unitsByType[key][0]
                                  }
                                ]
                          }
                        />
                      </Box>
                    );
                  })
                  .filter(v => v !== null)}
              </Stack>
            </Box>
            <Box mt={2} color={popoverColor}>
              <UnitOption
                type="format"
                label="Time Format"
                value={timeFormat}
                context="components.dashboard.header.unitToggle"
                changeHandler={(type: string, val: ClockType) => setTimeFormat(val)}
                options={[
                  {
                    value: ClockType.TwelveHour,
                    label: "12 hr"
                  },
                  {
                    value: ClockType.TwentyFourHour,
                    label: "24 hr"
                  }
                ]}
              />
            </Box>
            <Box mt={2}>
              <UnitOption
                type="theme"
                label="Theme"
                context="components.dashboard.header.unitToggle"
                value={theme.name}
                changeHandler={(type: string, val: ClockType) => setTheme(val)}
                options={[
                  {
                    value: "light",
                    label: "light"
                  },
                  {
                    value: "dark",
                    label: "dark"
                  }
                ]}
              />
            </Box>
            <Box mt={2} color={popoverColor}>
              <UnitOption
                type="type"
                label="Pressure"
                value={pressureType}
                context="components.dashboard.header.unitToggle"
                changeHandler={(type: string, val: PressureType) => setPressureType(val)}
                optionWidth={"200px"}
                options={[
                  {
                    value: PressureType.MeanSeaLevelPressure,
                    label: "MSLP"
                  },
                  {
                    value: PressureType.Altimeter,
                    label: "Altim."
                  },
                  {
                    value: PressureType.Station,
                    label: "Station"
                  }
                ]}
              />
            </Box>
          </Popover.Body>
        </Popover.Content>
      </Popover.Container>
      <SegmentedControl value={unitType} {...rest} onChange={handleChange} context={context}>
        <Button value={Units.imperial}>Imperial</Button>
        <Button value={Units.metric}>Metric</Button>
      </SegmentedControl>
    </Stack>
  );
};

export default UnitToggle;
