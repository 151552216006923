import Model from "../Model";
import _get from "lodash.get";
import { isMetricStore } from "../../react/common/hooks/useIsMetric";
import { unitForProp } from "./units";

export default abstract class Measurement extends Model {
  public get isMetric(): boolean {
    return isMetricStore;
  }

  public getUnits(prop: string): string {
    return unitForProp(prop, this.isMetric);
  }
}
