import { PlaceType, CountriesThatRequireStates } from "../../types/enums";
import Path from "./Path";

export default class Location extends Path {
  path: (CountriesThatRequireStates | string)[];
  type = PlaceType.Location;
  get displayName() {
    if (this.requiresState) {
      return [this.city, this.state].join(", ");
    }
    return [this.city, this.country].join(", ");
  }
  get queryId() {
    return [this.city, this.state, this.country].filter(item => item !== null).join(", ");
  }
  get isValid() {
    if (this.requiresState) {
      return this.place.length === 3;
    }
    return this.place.length === 2;
  }
  get requiresState() {
    return (Object.values(CountriesThatRequireStates) as string[]).includes(this.country);
  }
  get country(): string {
    return this.place[0];
  }
  get state(): string {
    return this.requiresState ? this.place[1] : null;
  }
  get city(): string {
    return this.requiresState ? this.place[2] : this.place[1];
  }
  get url(): string {
    return this.requiresState
      ? [this.country, this.state, this.city].join("/")
      : [this.country, this.city].join("/");
  }
}
