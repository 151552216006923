import React from "react";
import { css } from "@emotion/react";
import {
  Absolute,
  ThemeProvider,
  Theme,
  Box,
  //  StationMarker,
  GlobalStyle,
  CSSReset
} from "pws-design-system/design-system";
import StationMarker from "./StationMarker";
import Observation from "../../../../models/aeris/observation";
import { isWithinInterval, subHours } from "date-fns";
import _isNil from "lodash.isnil";
import { addMinutes } from "date-fns";
import createTheme from "pws-design-system/design-system/createTheme";
import { colors } from "pws-design-system/design-system//base/colors";

export const createColorScale = (colors: string[]): any => {
  return (colors || []).reduce((result: any, color, index) => {
    const indexKey = index === 0 ? "50" : `${index * 100}`;
    result[indexKey] = color;
    return result;
  }, {});
};

const DarkTheme = createTheme({
  name: "dark",
  colors: {
    bg: {
      ...createColorScale([...colors.brand.gray.levels].reverse()),
      base: {
        primary: colors.brand.gray.base,
        secondary: colors.brand.gray.levels[12],
        tertiary: colors.brand.gray.levels[10]
      },
      reversed: {
        primary: colors.white,
        secondary: colors.brand.gray.levels[1],
        tertiary: colors.brand.gray.levels[3]
      },
      surface: [
        colors.brand.gray.base,
        colors.brand.gray.levels[13],
        colors.brand.gray.levels[12],
        colors.brand.gray.levels[11],
        colors.brand.gray.levels[10],
        colors.brand.gray.levels[9]
      ]
    },
    text: {
      base: {
        primary: colors.text.light.primary,
        secondary: colors.text.light.secondary,
        tertiary: colors.text.light.tertiary
      },
      reversed: {
        primary: colors.text.dark.primary,
        secondary: colors.text.dark.secondary,
        tertiary: colors.text.dark.tertiary
      },
      error: "hsl(0,74%,59%)"
    }
  }
});

interface WrappedStationMarkerProps {
  observation: Observation;
  date?: Date;
  disabledDate?: Date;
  invalidDate?: Date;
  displayId: string;
  variant?: "primary" | "secondary";
  selected?: boolean;
  param: string;
}

/*
 * * Offsets a station marker by half of its size
 * * Wraps it in a theme so it can be rendered to as string and fed into the map app
 * * adds a link to link it to the station page
 */
export default function WrappedStationMarker({
  observation,
  displayId,
  variant,
  date = addMinutes(new Date(), 10),
  disabledDate = subHours(date, 2),
  invalidDate = subHours(date, 6),
  selected,
  param
}: WrappedStationMarkerProps): React.ReactElement {
  const interval = isWithinInterval(observation.date, {
    start: invalidDate,
    end: date
  });
  interface Observation {
    tempF: number;
    wind: number;
    dewpoint: number;
    windGust: number;
    precip: number;
    feelsLike: number;
    humidity: number;
    temp: string; // Assuming observation.temp is of type string
    date: Date; // Assuming observation.date is of type Date
    has(property: string): boolean;
  }

  interface ParamMapping {
    property: keyof Observation;
    label: keyof Observation;
  }

  const paramMappings: Record<string, ParamMapping> = {
    temps: { property: "tempF", label: "temp" },
    winds: { property: "wind", label: "wind" },
    dewpt: { property: "dewpoint", label: "dewpoint" },
    windGusts: { property: "windGust", label: "windGust" },
    ppt: { property: "precip", label: "precip" },
    feelsLike: { property: "feelsLike", label: "feelsLike" },
    rh: { property: "humidity", label: "humidity" }
  };
  const mapping = paramMappings[param];
  const paramString: string = mapping ? mapping.label : param;
  const paramValue: number | undefined = mapping ? observation[mapping.property] : undefined;
  const labelValue: string | undefined = mapping ? observation[mapping.label] : undefined;

  const value: string | number = interval
    ? paramValue !== undefined
      ? param == "ppt"
        ? paramValue
        : Math.round(paramValue)
      : "--"
    : "--";
  const dataType = interval ? param : "";
  const disabled =
    observation.has("temp") === false ||
    isWithinInterval(observation.date, {
      start: disabledDate,
      end: date
    }) === false;

  return (
    <ThemeProvider theme={DarkTheme}>
      <CSSReset />
      <GlobalStyle />
      <Absolute top="0" left="50%" transform="translateX(-50%)">
        <button
          data-observation={JSON.stringify(observation.data)}
          css={css`
            outline: none;
            :focus {
              outline: none;
            }
            p {
              height: 18px;
            }
          `}
        >
          <Box pointerEvents="none">
            <StationMarker
              name={_isNil(displayId) ? observation.displayId : displayId.toUpperCase()}
              variant={variant || "primary"}
              labelValue={
                observation.has(paramString)
                  ? param == "ppt"
                    ? labelValue
                    : Math.round(labelValue)
                  : "--"
              }
              value={value}
              disabled={disabled}
              dataType={dataType}
              style={{ lineHeight: 1.2 }}
              selected={selected}
            />
          </Box>
        </button>
      </Absolute>
    </ThemeProvider>
  );
}
