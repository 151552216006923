import Response from "./Response";
import _get from "lodash.get";

export default class SummaryResponse extends Response {
  get data() {
    const data = _get(this.apiResponse, "data", null);
    let dataKey = "data";

    if (Array.isArray(data)) {
      dataKey = "data[0]";
    }

    return _get(this.apiResponse, `${dataKey}`, null);
  }
}
