import SummaryResponse from "../../../../models/aeris/response/SummaryResponse";
import Summary from "../../../../models/aeris/summary/Summary";
import useQuery from "./useQuery";
import Response from "../../../../models/aeris/response/Response";

/**
 * Fetch daily dummaries for a place
 * @param {placeId} valid place id to request summaries for
 * @returns {Object} Daily Summary value object and fetch state
 */
const useDailySummaryQuery = (placeId: string) => {
  const postFetchHook = (Model: Summary, response: Response, setter: any) => {
    setter(new Model({ data: response.data.periods[0].summary, rawData: response }));
  };

  const { model: summary, fetchState } = useQuery(
    {
      endpoint: "observations/summary",
      place: placeId
    },
    Summary,
    SummaryResponse,
    null,
    postFetchHook
  );

  return { summary, fetchState };
};

export default useDailySummaryQuery;
