import React from "react";
import { SegmentedControl as _SegmentedControl } from "pws-design-system/design-system";
import { ThemeContainer } from "../../hooks/useTheme";
import _get from "lodash.get";

const SegmentedControl = (props: any): React.ReactElement => {
  const { context } = props;
  const { theme } = ThemeContainer.useContainer();
  const bg = _get(theme, `${context}.bg`, "brand.gray.100");
  const selectedVariantColor = _get(theme, `${context}.selectedVariantColor`, "dark");
  const variantColor = _get(theme, `${context}.variantColor`, "dark");

  return (
    <_SegmentedControl
      bg={bg}
      p="2px"
      size="xs"
      spacing={0}
      variant="link"
      variantColor={variantColor}
      selectedVariant="outline-fill"
      selectedVariantColor={selectedVariantColor}
      rounded="full"
      {...props}
    />
  );
};

export default SegmentedControl;
