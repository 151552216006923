import React, { useState } from "react";
import { Absolute, Box, defaultTheme, CircularMeter, Text } from "pws-design-system/design-system";
import { colorForValue } from "pws-design-system/design-system/utils/colors";
import MeterProps from "./MeterProps";
import _isNil from "lodash.isnil";
import ArrowIcon from "../../../../../../common/components/arrow-icon/";
import { ThemeContainer } from "../../../../../../common/hooks/useTheme";

const configForSize = (size: string): any => {
  switch (size) {
    case "sm":
      return {
        wind: {
          ring: {
            height: 64,
            width: 64,
            padding: 5,
            top: -2
          },
          arrow: {
            scale: 0.7
          },
          label: {
            top: 18,
            fontSize: "11px"
          }
        }
      };
    case "md":
      return {
        wind: {
          ring: {
            height: 96,
            width: 96,
            padding: 16,
            top: -3
          },
          arrow: {
            scale: 0.85
          },
          label: {
            top: 32,
            fontSize: "15px"
          }
        }
      };
    case "lg":
      return {
        wind: {
          ring: {
            height: 112,
            width: 112,
            padding: 16,
            top: -3
          },
          arrow: {
            scale: 1
          },
          label: {
            top: 35,
            fontSize: "15px"
          }
        }
      };
  }

  return null;
};

function WindMeter({ observation, size = "md", animationDelay = 0, ...rest }: MeterProps) {
  const { theme } = ThemeContainer.useContainer();
  const value = observation.windMph;
  const labelValue = observation.wind;
  const units = observation.windUnits;
  const formatter = (value: any) => parseFloat(value).toFixed(1);
  const opts = size ? configForSize(size) : {};

  const [lastAngle, setLastAngle] = useState(0);
  const [rotation, setRotation] = useState(0);

  const windAngle = observation.windDirectionDegrees;
  const angleDelta = Math.abs(lastAngle - windAngle);
  let arrowHeight = 115;
  if (size === "md") {
    arrowHeight = 125;
  } else if (size === "lg") {
    arrowHeight = 135;
  }

  if (windAngle !== lastAngle) {
    let rot = windAngle;
    if (rot === 360) rot = 0;
    setLastAngle(windAngle);
    setRotation(rot);
  }

  return (
    <Box position="relative">
      {observation.wind > 0 && (
        <>
          <Box position="absolute" top={`${opts.wind.label.top}px`} width="100%" textAlign="center">
            {observation.has("windDirection") && (
              <Text as={"span"} fontSize={opts.wind.label.fontSize} fontWeight="bold">
                {observation.windDirection}
              </Text>
            )}
          </Box>
          <Absolute
            height={"100%"}
            width={"100%"}
            top={`${opts.wind.ring.top}px`}
            padding={`${opts.wind.ring.padding}px`}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              width={`${opts.wind.ring.width}px`}
              height={`${opts.wind.ring.height}px`}
              borderRadius="50%"
              borderStyle="solid"
              border="2px"
              borderColor={theme.components.meter.bg}
            ></Box>
          </Absolute>
          {observation.has("windDirectionDegrees") &&
            observation.windDirection.toUpperCase() !== "VRB" && (
              <Absolute
                height={"100%"}
                width={"100%"}
                top={0}
                padding={"16px"}
                paddingTop={"9px"}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  width="24px"
                  height={`${arrowHeight}px`}
                  transform={`rotate(${rotation}deg) scale(${opts.wind.arrow.scale})`}
                  transition="transform 1s cubic-bezier(0.770, 0.000, 0.175, 1.000)"
                  zIndex={0}
                >
                  <ArrowIcon
                    theme={{ fill: theme.components.map.panel.windMeter.arrowIcon.fill }}
                  />
                </Box>
              </Absolute>
            )}
        </>
      )}
      <CircularMeter
        variant={theme.name}
        size={size}
        disabled={_isNil(value) === true}
        label="Winds"
        range={{ min: 0, max: 70 }}
        value={value}
        labelValue={labelValue}
        color={["#666", colorForValue(defaultTheme.colors.weather.winds, value) || "#666"]}
        units={units}
        formatter={formatter}
        animation={{ delay: animationDelay }}
        bgColor={theme.components.meter.bg}
        disabledBgColor={theme.components.meter.disabledBgColor}
        {...rest}
      />
    </Box>
  );
}

export default WindMeter;
