import * as React from "react";
// import { Box } from "../box";
// import { Text } from "../text";
// import { Stack, ZStack } from "../stack";
// import { Svg } from "../icon";
//import { colors } from "../../base/colors";
//import { isLight, colorForValue } from "../../utils/colors";

// import { colors } from "pws-design-system/design-system/base/colors";
import { colors } from "./colors";
import { isLight, colorForValue } from "pws-design-system/design-system/utils/colors";
import {
  Absolute,
  ThemeProvider,
  Theme,
  Box,
  Text,
  Svg,
  ZStack,
  //  StationMarker,
  GlobalStyle,
  CSSReset
} from "pws-design-system/design-system";
type StationMarkerProps = {
  variant?: "primary" | "secondary";
  name: string;
  dataType: string;
  value: number;
  labelValue?: number;
  disabled?: boolean;
  selected?: boolean;
};

const StationMarker: React.FC<StationMarkerProps> = (
  props: StationMarkerProps
): React.ReactElement => {
  const {
    variant = "primary",
    name,
    dataType,
    value,
    labelValue,
    disabled,
    selected,
    ...rest
  } = props;

  let colorType = dataType;
  if (dataType == "feelsLike") colorType = "temps";
  if (dataType == "rh") colorType = "humidity";
  if (dataType == "ppt") colorType = "precip";

  const isPrimary = variant === "primary";
  const colorRamp = colors.weather[colorType];

  let size = isPrimary ? 56 : 42;
  let outerSize = size - (isPrimary ? 14 : 8);
  let innerSize = outerSize - (isPrimary ? 6 : 4);
  let valueSize = isPrimary ? 22 : 17;
  let valueHeight = isPrimary ? 21 : 16;
  let textSize = 16;
  const valueColor = colorRamp ? colorForValue(colorRamp, parseFloat(`${value}`)) : "#666";
  const valueTextColor = isLight(valueColor) ? "text.dark.primary" : "text.light.primary";

  if (selected) {
    size += 10;
    outerSize += 2;
    innerSize += 2;
    valueSize += 2;
    valueHeight += 2;
    textSize += 2;
  }

  const lineHeight = Math.round(textSize * 1.1);

  return (
    <ZStack width={`${size}px`} height={`${size}px`} mx="auto" {...rest}>
      <Box
        width={`${size}px`}
        height={`${size}px`}
        anchor="center"
        bg={disabled ? "rgba(0,0,0,0.5)" : selected ? "rgba(0,0,0,0.9)" : "rgba(0,0,0,0.7)"}
        rounded="full"
      />
      <Box
        width={`${outerSize}px`}
        height={`${outerSize}px`}
        anchor="center"
        bg={disabled ? "#ccc" : "#fff"}
        rounded="full"
      />
      <Box
        width={`${innerSize}px`}
        height={`${innerSize}px`}
        bg={disabled ? "#777" : valueColor}
        anchor="center"
        rounded="full"
      />
      <Box anchor="center">
        <Text
          variant="value"
          fontSize={`${valueSize}px`}
          color={disabled ? "#ddd" : valueTextColor}
          lineHeight="normal"
        >
          {undefined !== labelValue ? labelValue : value}
        </Text>
      </Box>
      {isPrimary && (
        <Svg
          viewBox="0 0 200 22"
          width="200px"
          height="20px"
          anchor="center-x"
          top={`${size - 3}px`}
        >
          <text
            x="100"
            y="17"
            fontFamily="'Calibre',Helvetica,Arial"
            fontWeight="700"
            fontSize={textSize}
            fill="#fff"
            stroke="#fff"
            strokeWidth="3px"
            textAnchor="middle"
            paintOrder="stroke"
          >
            {name}
          </text>
          <text
            x="100"
            y="17"
            fontFamily="'Calibre',Helvetica,Arial"
            fontWeight="700"
            fontSize={textSize}
            fill={disabled ? "#777" : "#333"}
            textAnchor="middle"
            paintOrder="stroke"
          >
            {name}
          </text>
        </Svg>
      )}
    </ZStack>
  );
};

StationMarker.defaultProps = {
  dataType: "temps"
};

export default StationMarker;
