import Path from "./Path";
import { PlaceType, Views } from "../../types/enums";

export default class Station extends Path {
  type = PlaceType.Station;

  get hasStationType() {
    return (
      this.queryId.startsWith("pws_") ||
      this.queryId.startsWith("mid_") ||
      this.queryId.startsWith("aus_")
    );
  }

  get stationType() {
    if (this.hasStationType === true) {
      return this.queryId.slice(0, 3);
    }
    return null;
  }

  get displayName() {
    if (this.hasStationType) {
      return this.queryId.slice(4).toLowerCase();
    }
    return this.queryId;
  }

  get isValid() {
    if (Array.isArray(this.place)) {
      return this.place.length === 1 && !this.place[0].startsWith("bloomsky_");
    }
    return false;
  }

  get queryId() {
    return this.place[0];
  }

  get urls() {
    if (this.hasStationType) {
      return {
        [Views.Dashboard]: `/${this.type}/${this.stationType}/${this.displayName}/`,
        [Views.Forecast]: `/${this.type}/${Views.Forecast}/${this.stationType}/${this.displayName}/`,
        [Views.Historical]: `/${this.type}/${Views.Historical}/${this.stationType}/${this.displayName}/`,
        [Views.Map]: `/${this.type}/${Views.Map}/${this.stationType}/${this.displayName}`
      };
    }
    return {
      [Views.Dashboard]: `/${this.type}/${this.queryId}/`,
      [Views.Forecast]: `/${this.type}/${Views.Forecast}/${this.queryId}/`,
      [Views.Historical]: `/${this.type}/${Views.Historical}/${this.queryId}/`,
      [Views.Map]: `/${this.type}/${Views.Map}/${this.queryId}/`
    };
  }
  get desktopUrls() {
    if (this.hasStationType) {
      return {
        [Views.Dashboard]: `/${this.type}/${this.stationType}/${this.displayName}/`,
        [Views.Forecast]: `/${this.type}/${Views.Forecast}/${this.stationType}/${this.displayName}/`,
        [Views.Map]: `/${this.type}/${Views.Map}/${this.stationType}/${this.displayName}/`
      };
    }
    return {
      [Views.Dashboard]: `/${this.type}/${this.queryId}/`,
      [Views.Forecast]: `/${this.type}/${Views.Forecast}/${this.queryId}/`,
      [Views.Map]: `/${this.type}/${Views.Map}/${this.queryId}/`
    };
  }
  get mobileUrls() {
    if (this.hasStationType) {
      return {
        [Views.Dashboard]: `/${this.type}/${this.stationType}/${this.displayName}/`,
        [Views.Forecast]: `/${this.type}/${Views.Forecast}/${this.stationType}/${this.displayName}/`,
        [Views.Historical]: `/${this.type}/${Views.Historical}/${this.stationType}/${this.displayName}/`,
        [Views.Map]: `/${this.type}/${Views.Map}/${this.stationType}/${this.displayName}/`
      };
    }
    return {
      [Views.Dashboard]: `/${this.type}/${this.queryId}/`,
      [Views.Forecast]: `/${this.type}/${Views.Forecast}/${this.queryId}/`,
      [Views.Historical]: `/${this.type}/${Views.Historical}/${this.queryId}/`,
      [Views.Map]: `/${this.type}/${Views.Map}/${this.queryId}/`
    };
  }
}
