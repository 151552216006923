import React from "react";

const NavigationIcon = ({ size = 24, theme = { fill: "#000" } }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <g transform="rotate(180,12,12)">
        <path d="M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71z" fill={theme.fill} />
      </g>
    </svg>
  );
};

export default NavigationIcon;
