import React, { useEffect, useState } from "react";
import { Global, css } from "@emotion/react";
import InteractiveMap from "@aerisweather/javascript-sdk/dist/maps/interactive/InteractiveMap";
import InteractiveMapApp from "@aerisweather/javascript-sdk/dist/apps/InteractiveMapApp";
import { subHours, addMinutes } from "date-fns";
import { Box, Flex, Spinner, Absolute } from "pws-design-system/design-system";
import { renderToString } from "react-dom/server";
import aeris from "../../../../api";
import Collection from "../../../../models/Collection";
import Observation from "../../../../models/aeris/observation";
import Place from "../../../../models/aeris/place";
import Map from "./Map";
import WrappedStationMarker from "./WrappedStationMarker";
import _isNil from "lodash.isnil";
import _merge from "lodash.merge";
import DelayedRender from "../delayed-renderer/DelayedRender";
import _noop from "lodash.noop";
import { $ } from "../../../../utils";

export const obsFields = [
  "loc.lat",
  "loc.long",
  "ob.tempF",
  "ob.tempC",
  "ob.dewpointF",
  "ob.dewpointC",
  "ob.feelslikeF",
  "ob.feelslikeC",
  "id",
  "ob.dateTimeISO",
  "ob.icon",
  "ob.weatherPrimary",
  "ob.pressureMB",
  "ob.pressureIN",
  "ob.spressureMB",
  "ob.spressureIN",
  "ob.altimeterMB",
  "ob.altimeterIN",
  "ob.humidity",
  "ob.precipMM",
  "ob.precipIN",
  "ob.windSpeedMPH",
  "ob.windSpeedKPH",
  "ob.windGustMPH",
  "ob.windGustKPH",
  "ob.windDir",
  "ob.windDirDEG"
];

export const stationMarkerConfigurator = (
  data: any,
  map: InteractiveMap,
  param: string,
  selected: boolean = false,
  label: string = null,
  variant: "primary" | "secondary" = null
): any => {
  const zoom = map.getZoom();
  const observation = new Observation({ data });

  const params = new URLSearchParams(window.location.search);
  //a hack around the obs going back to the defualt value
  //whenever the map redrew markers on pan/zoom
  //grab marker ob from url if it was selected and override default
  if (params.has("ob")) {
    param = params.get("ob");
  }
  // default is to set the variant based on zoom level
  if (!variant) {
    variant = zoom > 7 ? "primary" : "secondary";
  }

  let size = 76;
  if (variant === "secondary") {
    size = 42;
  }
  if (selected) {
    size += 10;
  }

  const date = new Date();
  const dates = {
    date: addMinutes(date, 10), //station may have reported in future relative to browser time
    disabledDate: subHours(date, 2),
    invalidDate: subHours(date, 6)
  };

  return {
    html: renderToString(
      <Box>
        <WrappedStationMarker
          variant={variant}
          date={dates.date}
          disabledDate={dates.disabledDate}
          invalidDate={dates.invalidDate}
          observation={observation}
          displayId={label || observation.displayId}
          selected={selected}
          param={param}
        />
      </Box>
    ),
    size: [size, size]
  };
};

const defaultConfig = {
  strategy: "leaflet",
  zoom: 8
};

function configBuilder(type: "InteractiveMap" | "InteractiveMapApp", config: {}, place: Place) {
  if (type === "InteractiveMap") {
    return _merge({}, defaultConfig, config);
  }
  if (type === "InteractiveMapApp") {
    return _merge({}, { map: defaultConfig }, config);
  }
}

async function fetchPlace(id: string): Promise<Place> {
  const request = aeris
    .api()
    .endpoint("places")
    .place(id)
    .format("json")
    .limit(1);
  const response = await request.get();
  return new Place({ placeId: id, data: response.data });
}

interface MapControllerProps {
  height?: string;
  place?: Place;
  type: "InteractiveMap" | "InteractiveMapApp";
  config?: any;
  disableScrollWheel?: boolean;
  mapReadyHandler?: (map: InteractiveMap, app: InteractiveMapApp) => any;
}

export default function MapController({
  height = "100%",
  place: initialPlace,
  type,
  config = {},
  disableScrollWheel = false,
  mapReadyHandler = _noop
}: MapControllerProps): React.ReactElement {
  const [place, setPlace] = useState(initialPlace || new Place({ placeId: ":auto", data: {} }));
  const [map, setMap] = useState(null);
  const [isLoadingPlace, setIsLoadingPlace] = useState(true);

  function handleMapReady(map: InteractiveMap, app: InteractiveMapApp) {
    setMap(map);
    mapReadyHandler(map, app);

    if (disableScrollWheel === true) {
      map.strategy.map.scrollWheelZoom.disable();
    }
  }

  const updatePlace = (place: Place) => {
    if (place.hasAll(["lat", "lon"])) {
      setPlace(place);
    } else if (place.has("placeId")) {
      setIsLoadingPlace(true);
      fetchPlace(place.placeId).then(place => {
        setPlace(place);
      });
    }
  };

  // set active place once map is rendered
  useEffect(() => {
    if (place) {
      updatePlace(place);
    }
  }, [map]);

  // update map center on place change
  useEffect(() => {
    if (map && place) {
      const params = new URLSearchParams(window.location.search);

      if (params.has("lon") && params.has("lat")) {
        const lat = params.get("lat");
        const lon = params.get("lon");
        const zoom = params.get("zoom");

        map.setCenter({ lat: lat, lon: lon });
        map.setZoom(zoom);
      } else {
        map.setCenter({ lat: place.lat, lon: place.lon });
      }
      setIsLoadingPlace(false);
    }
  }, [place, map]);

  if (map && initialPlace && initialPlace.placeId !== place.placeId) {
    updatePlace(initialPlace);
  }

  useEffect(() => {
    // the tooltip likes to stick around on page transitions
    return () => {
      $(".awxjs__ui-tooltip").forEach(el => el.remove());
    };
  }, []);

  return (
    <Box height={height} position="relative">
      <Global
        styles={css`
          .leaflet-marker-icon {
            outline: none;
          }
          .awxjs__loader > svg {
            margin: -10px 0 0 -10px;
            padding: 0;
          }
        `}
      />
      <Box zIndex={0} position="relative" height="100%">
        <Map
          height="100%"
          mapReadyHandler={handleMapReady}
          type={type}
          config={configBuilder(type, config, place)}
          zIndex={0}
        />
      </Box>
      {isLoadingPlace && (
        <DelayedRender delay={100}>
          <Absolute top="0" left="0" right="0" bottom="0" zIndex={1} bg="rgba(255,255,255,0.9)">
            <Absolute top="50%" left="50%" transform="translateX(-50%) translateY(-50%)">
              <Spinner></Spinner>
            </Absolute>
          </Absolute>
        </DelayedRender>
      )}
    </Box>
  );
}
