import Measurement from "../Measurement";
import Normal from "../normal/Normal";
import { unitStore } from "../../../react/common/hooks/stores/useUnitStore";
import DataFormatter from "../../../models/aeris/observation/DataFormatter";
import { UnitCategory, UnitTypes, ApiProps, ApiPropGroups, Units } from "../../../types/enums";
import _get from "lodash.get";
import { pressureStore } from "../../../react/common/hooks/stores/usePressureTypeStore";
import { kmhToMs } from "../units";

const sealevelToStationPressure = (sealevelPressure, elevation) => {
  const x = (288 - 0.0065 * elevation) / 288;
  const stationPressure = sealevelPressure / Math.pow(x, 5.2561);
  return stationPressure.toFixed(2);
};

export default class Summary extends Measurement {
  public normal: Normal = null;

  public get tempUnits(): string {
    const unitPref = unitStore[UnitCategory.Temp];
    return unitPref === "metric" ? "°C" : "°F";
  }
  public get dewpointUnits(): string {
    return this.tempUnits;
  }
  public get pressureUnits(): string {
    const unitPref = unitStore[UnitCategory.Pressure];
    return unitPref === "metric" ? "mb" : "in";
  }
  public get speedUnits(): string {
    const unitPref = unitStore[UnitCategory.Speed];
    const unitChoice = unitStore.values["speed"];
    if (unitStore["speed"] === "custom") {
      if (unitChoice === "m/s") {
        return "m/s";
      }
      return "kts";
    }
    return unitPref === "metric" ? "km/h" : "mph";
  }
  public get windUnits(): string {
    return this.speedUnits;
  }
  public get precipUnits(): string {
    const unitPref = unitStore[UnitCategory.Rain];
    return unitPref === "metric" ? "mm" : "in";
  }

  public get minTempF(): number | null {
    return this.getData(`temp.minF`);
  }
  public get minTempC(): number | null {
    return this.getData(`temp.minC`);
  }
  public get minTemp(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = unitPref === "metric" ? this.minTempC : this.minTempF;
    return value;
  }

  public get minTempFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.minTemp;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get minTempFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.minTemp;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  public get maxTempF(): string | null {
    return this.getData(`temp.maxF`);
  }
  public get maxTempC(): string | null {
    return this.getData(`temp.maxC`);
  }
  public get maxTemp(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = unitPref === "metric" ? this.maxTempC : this.maxTempF;
    return value;
  }

  public get maxTempFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.maxTemp;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get maxTempFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.maxTemp;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  private get dateTimeIso(): string | null {
    return this.getData("dateTimeISO");
  }
  public get date() {
    return new Date(this.dateTimeIso);
  }
  get formattedObservationTime(): string | null {
    if (this.dateTimeIso !== null) {
      return new Date(this.dateTimeIso).toLocaleTimeString("en-US", {
        timeStyle: "short"
      });
    }
    return null;
  }

  public get avgHighTempF(): number | null {
    return this.getData("highTempAvg");
  }

  public get avgLowTempF(): number | null {
    return this.getData("lowTempAvg");
  }

  public get avgTempF(): number | null {
    return this.getData(`temp.avgF`);
  }
  public get avgTempC(): number | null {
    return this.getData(`temp.avgC`);
  }
  public get avgTemp(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = unitPref === "metric" ? this.avgTempC : this.avgTempF;
    return value;
  }

  public get avgTempFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.avgTemp;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get avgTempFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.avgTemp;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  public get feelsLikeC(): number | null {
    return this.getData("feelslikeC");
  }
  public get feelsLikeF(): number | null {
    return this.getData("feelslikeF");
  }
  public get feelsLike(): number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = unitPref === "metric" ? this.feelsLikeC : this.feelsLikeF;
    return value;
  }
  public get feelsLikeFormatted() {
    if (this.feelsLike === null) {
      return null;
    }
    const dataFormatter = new DataFormatter(
      UnitCategory.Temp,
      unitStore[UnitCategory.Temp],
      this.feelsLike
    );
    return dataFormatter.formattedValue;
  }

  public get dewptMinC(): number | null {
    return this.getData(`dewpt.minC`);
  }
  public get dewptMinF(): number | null {
    return this.getData(`dewpt.minF`);
  }
  public get dewptMin(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = unitPref === "metric" ? this.dewptMinC : this.dewptMinF;
    return value;
  }

  public get dewptMinFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.dewptMin;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get dewptMinFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.dewptMin;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  public get dewptMaxC(): number | null {
    return this.getData(`dewpt.maxC`);
  }
  public get dewptMaxF(): number | null {
    return this.getData(`dewpt.maxF`);
  }
  public get dewptMax(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = unitPref === "metric" ? this.dewptMaxC : this.dewptMaxF;
    return value;
  }

  public get dewptMaxFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.dewptMax;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get dewptMaxFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.dewptMax;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  public get dewptAvgC(): number | null {
    return this.getData(`dewpt.avgC`);
  }
  public get dewptAvgF(): number | null {
    return this.getData(`dewpt.avgF`);
  }

  public get dewptAvg(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = unitPref === "metric" ? this.dewptAvgC : this.dewptAvgF;
    return value;
  }

  public get dewptAvgFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.dewptAvg;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get dewptAvgFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.dewptAvg;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  public get period(): string | null {
    return this.getData("period");
  }

  public get rhMin(): string | null {
    return this.getData(`rh.min`);
  }
  public get rhMax(): string | null {
    return this.getData(`rh.max`);
  }
  public get rhAvg(): string | null {
    return this.getData(`rh.avg`);
  }

  public get minWindKph(): number | null {
    return this.getData(`wind.minKPH`);
  }
  public get minWindMph(): number | null {
    return this.getData(`wind.minMPH`);
  }
  public get minWindKts(): number | null {
    return this.getData(`wind.minKTS`);
  }

  public get minWind(): string | number | null {
    const unitPref = unitStore[UnitCategory.Speed];
    if (unitPref === "custom") {
      return this.minWindKts;
    }
    const value = unitPref === "metric" ? this.minWindKph : this.minWindMph;
    return value;
  }

  public get minWindFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Speed];
    const value = this.minWind;
    const dataFormatter = new DataFormatter(UnitCategory.Speed, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get minWindFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Speed];
    const value = this.minWind;
    const dataFormatter = new DataFormatter(UnitCategory.Speed, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  public get maxWindKph(): number | null {
    return this.getData(`wind.maxKPH`);
  }
  public get maxWindMph(): number | null {
    return this.getData(`wind.maxMPH`);
  }
  public get maxWindKts(): number | null {
    return this.getData(`wind.maxKTS`);
  }
  public get maxWindMps() {
    const kmh = this.maxWindKph;
    const mps = kmhToMs(kmh);
    return mps;
  }

  public get maxWind(): string | number | null {
    const unitPref = unitStore[UnitCategory.Speed];
    const unitChoice = unitStore.values["speed"];
    if (unitPref === "custom") {
      if (unitChoice === "kts") {
        return this.maxWindKts;
      } else if (unitChoice === "m/s") {
        return this.maxWindMps;
      }
    }
    const value = unitPref === "metric" ? this.maxWindKph : this.maxWindMph;
    return value;
  }

  public get maxWindFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Speed];
    const value = this.maxWind;
    const dataFormatter = new DataFormatter(UnitCategory.Speed, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get maxWindFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Speed];
    const value = this.maxWind;
    const dataFormatter = new DataFormatter(UnitCategory.Speed, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  public get avgWindKph(): string | null {
    return this.getData(`wind.avgKPH`);
  }
  public get avgWindMph(): string | null {
    return this.getData(`wind.avgMPH`);
  }
  public get avgWind(): string | number | null {
    const unitPref = unitStore[UnitCategory.Speed];
    const value = unitPref === "metric" ? this.avgWindKph : this.avgWindMph;
    return value;
  }

  public get avgWindFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Speed];
    const value = this.avgWind;
    const dataFormatter = new DataFormatter(UnitCategory.Speed, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get avgWindFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Speed];
    const value = this.avgWind;
    const dataFormatter = new DataFormatter(UnitCategory.Speed, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  public get windGustKph() {
    return this.getData(`wind.gustKPH`);
  }
  public get windGustMph() {
    return this.getData(`wind.gustMPH`);
  }
  public get windGustKts() {
    return this.getData(`wind.gustKTS`);
  }
  public get windGustMps() {
    const kmh = this.windGustKph;
    const mps = kmhToMs(kmh);
    return mps;
  }

  public get windGust() {
    const unitPref = unitStore[UnitCategory.Speed];
    const unitChoice = unitStore.values["speed"];
    if (unitPref === "custom") {
      if (unitChoice === "kts") {
        return this.windGustKts;
      } else if (unitChoice === "m/s") {
        return this.windGustMps;
      }
    }
    const value = unitPref === "metric" ? this.windGustKph : this.windGustMph;
    return value;
  }
  public get windGustFormatted(): string | null {
    if (this.windGust === null) {
      return null;
    }
    const dataFormatter = new DataFormatter(
      UnitCategory.Speed,
      unitStore[UnitCategory.Speed],
      this.windGust
    );

    return dataFormatter.formattedValue;
  }

  public get minSpressureIn(): string | null {
    return this.getData(`spressure.minIN`);
  }
  public get maxSpressureIn(): string | null {
    return this.getData(`spressure.maxIN`);
  }

  public get minSpressureMb(): string | null {
    return this.getData(`spressure.minMB`);
  }
  public get maxSpressureMb(): string | null {
    return this.getData(`spressure.maxMB`);
  }
  public get avgSpressureIn(): string | null {
    return this.getData(`spressure.avgIN`);
  }
  public get avgSpressureMb(): string | null {
    return this.getData(`spressure.avgMB`);
  }
  public get avgSpressure(): string | null {
    const unitPref = unitStore[UnitCategory.Pressure];
    const value = unitPref === "metric" ? this.avgSpressureMb : this.avgSpressureIn;
    return value;
  }

  public get minSpressure(): string | null {
    return this.isMetric ? this.minSpressureMb : this.minSpressureIn;
  }
  public get maxSpressure(): string | null {
    return this.isMetric ? this.maxSpressureMb : this.maxSpressureIn;
  }

  public get minPressureIn(): string | null {
    return this.getData(`pressure.minIN`);
  }
  public get minPressureMb(): string | null {
    return this.getData(`pressure.minMB`);
  }

  public get minPressure(): string | number | null {
    const unitPref = unitStore[UnitCategory.Pressure];
    const value = unitPref === "metric" ? this.minPressureMb : this.minPressureIn;
    return value;
  }

  public get minPressureFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Pressure];
    const pressureType = pressureStore.type;
    const value = this.minPressure;
    const dataFormatter = new DataFormatter(UnitCategory.Pressure, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get minPressureFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Pressure];
    const pressureType = pressureStore.type;
    const value = this.minPressure;
    const dataFormatter = new DataFormatter(UnitCategory.Pressure, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  public get maxPressureIn(): string | null {
    return this.getData(`pressure.maxIN`);
  }
  public get maxPressureMb(): string | null {
    return this.getData(`pressure.maxMB`);
  }
  public get maxPressure(): string | number | null {
    const unitPref = unitStore[UnitCategory.Pressure];
    const value = unitPref === "metric" ? this.maxPressureMb : this.maxPressureIn;
    return value;
  }

  public get maxPressureFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Pressure];
    const value = this.maxPressure;
    const dataFormatter = new DataFormatter(UnitCategory.Pressure, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get maxPressureFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Pressure];
    const value = this.maxPressure;
    const dataFormatter = new DataFormatter(UnitCategory.Pressure, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  public get avgPressureIn(): string | null {
    return this.getData(`pressure.avgIN`);
  }

  public get avgPressureMb(): string | null {
    return this.getData(`pressure.avgMB`);
  }

  public get avgPressure(): string | number | null {
    const unitPref = unitStore[UnitCategory.Pressure];
    const value = unitPref === "metric" ? this.avgPressureMb : this.avgPressureIn;
    return value;
  }

  public get avgPressureFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Pressure];
    const value = this.avgPressure;
    const dataFormatter = new DataFormatter(UnitCategory.Pressure, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get avgPressureFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Pressure];
    const value = this.avgPressure;
    const dataFormatter = new DataFormatter(UnitCategory.Pressure, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  public get avgPressureByType(): string | number | null {
    const pressureType = pressureStore.type;
    const unitPref = unitStore[UnitCategory.Pressure];
    let value;

    if (pressureType === "altimeter") {
      value = this.avgAltimeter;
    } else if (pressureType === "mslp") {
      value = this.avgSpressure;
    } else {
      value = this.avgPressure;
    }

    const dataFormatter = new DataFormatter(UnitCategory.Pressure, unitPref, Number(value));
    return dataFormatter.formattedValue;
  }

  public get minAltimeterMb(): string | null {
    return this.getData(`altimeter.minMB`);
  }

  public get minAltimeterIn(): string | null {
    return this.getData(`altimeter.minIN`);
  }

  public get maxAltimeterMb(): string | null {
    return this.getData(`altimeter.maxMB`);
  }

  public get maxAltimeterIn(): string | null {
    return this.getData(`altimeter.maxIN`);
  }

  public get avgAltimeterMb(): string | null {
    return this.getData(`altimeter.avgMB`);
  }

  public get avgAltimeterIn(): string | null {
    return this.getData(`altimeter.avgIN`);
  }

  public get avgAltimeter(): string | null {
    const unitPref = unitStore[UnitCategory.Pressure];
    const value = unitPref === "metric" ? this.avgAltimeterMb : this.avgAltimeterIn;
    return value;
  }

  public get minPressureByType(): string | number | null {
    const pressureType = pressureStore.type;
    const unitPref = unitStore[UnitCategory.Pressure];
    if (pressureType === "altimeter") {
      return unitPref === "metric" ? this.minAltimeterMb : this.minAltimeterIn;
    }

    if (pressureType === "mslp") {
      return unitPref === "metric" ? this.minSpressureMb : this.minSpressureIn;
    }
    return unitPref === "metric" ? this.minPressureMb : this.minPressureIn;
  }

  public get maxPressureByType(): string | number | null {
    const pressureType = pressureStore.type;
    const unitPref = unitStore[UnitCategory.Pressure];
    if (pressureType === "altimeter") {
      return unitPref === "metric" ? this.maxAltimeterMb : this.maxAltimeterIn;
    }

    if (pressureType === "mslp") {
      return unitPref === "metric" ? this.maxSpressureMb : this.maxSpressureIn;
    }
    return unitPref === "metric" ? this.maxPressureMb : this.maxPressureIn;
  }

  private get stationElevationM() {
    const elevation = Array.isArray(this.rawData.data)
      ? _get(this.rawData, "data[0].profile.elevM")
      : _get(this.rawData, "data.profile.elevM");
    return elevation;
  }

  public get minStationPressureIn() {
    const sealevelPressure = this.minSpressureIn;
    const stationPressure = sealevelToStationPressure(sealevelPressure, this.stationElevationM);
    return stationPressure;
  }

  public get minStationPressureMb() {
    return this.minStationPressureIn * 33.8639;
  }

  public get minStationPressure() {
    const unitPref = unitStore[UnitCategory.Pressure];
    const value = unitPref === "metric" ? this.minStationPressureMb : this.minStationPressureIn;
    return value;
  }

  public get minStationPressureFormatted() {
    const unitPref = unitStore[UnitCategory.Pressure];
    const value = this.minStationPressure;
    const dataFormatter = new DataFormatter(UnitCategory.Pressure, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get maxStationPressureIn() {
    const sealevelPressure = this.maxSpressureIn;
    const stationPressure = sealevelToStationPressure(sealevelPressure, this.stationElevationM);
    return stationPressure;
  }

  public get maxStationPressureMb() {
    return this.maxStationPressureIn * 33.8639;
  }

  public get maxStationPressure() {
    const unitPref = unitStore[UnitCategory.Pressure];
    const value = unitPref === "metric" ? this.maxStationPressureMb : this.maxStationPressureIn;
    return value;
  }

  public get maxStationPressureFormatted() {
    const unitPref = unitStore[UnitCategory.Pressure];
    const value = this.maxStationPressure;
    const dataFormatter = new DataFormatter(UnitCategory.Pressure, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get minStationPressureFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Pressure];
    const value = this.minStationPressure;
    const dataFormatter = new DataFormatter(UnitCategory.Pressure, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  public get maxStationPressureFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Pressure];
    const value = this.maxStationPressure;
    const dataFormatter = new DataFormatter(UnitCategory.Pressure, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  public getPressuresByType() {
    const unitPref = unitStore[UnitCategory.Pressure];
    const pressureType = pressureStore.type;

    if (pressureType === "altimeter") {
      if (unitPref === "metric") {
        return {
          max: this.getData("altimeter.maxMB"),
          min: this.getData("altimeter.minMB"),
          avg: this.getData("altimeter.avgMB")
        };
      }
      return {
        max: this.getData("altimeter.maxIN"),
        min: this.getData("altimeter.minIN"),
        avg: this.getData("altimeter.avgIN")
      };
    }

    if (pressureType === "mslp") {
      if (unitPref === "metric") {
        return {
          max: this.getData("spressure.maxMB"),
          min: this.getData("spressure.minMB"),
          avg: this.getData("spressure.avgMB")
        };
      }
      return {
        max: this.getData("spressure.maxIN"),
        min: this.getData("spressure.minIN"),
        avg: this.getData("spressure.avgIN")
      };
    }

    if (unitPref === "metric") {
      return {
        max: this.getData("pressure.maxMB"),
        min: this.getData("pressure.minMB"),
        avg: this.getData("pressure.avgMB")
      };
    }
    return {
      max: this.getData("pressure.maxIN"),
      min: this.getData("pressure.minIN"),
      avg: this.getData("pressure.avgIN")
    };
  }

  public get precipTotalIn(): number | null {
    return this.getData(`precip.totalIN`);
  }
  public get precipTotalMm(): number | null {
    return this.getData(`precip.totalMM`);
  }
  // public get precipTotal(): string | null {
  //   return this.isMetric ? this.precipTotalMm : this.precipTotalIn;
  // }

  public get precipTotal(): string | number | null {
    const unitPref = unitStore[UnitCategory.Rain];
    const value = unitPref === "metric" ? this.precipTotalMm : this.precipTotalIn;
    return value;
  }

  public get precipTotalFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Rain];
    const value = this.precipTotal;
    const dataFormatter = new DataFormatter(UnitCategory.Rain, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get precipTotalFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Rain];
    const value = this.precipTotal;
    const dataFormatter = new DataFormatter(UnitCategory.Rain, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  get skyCover() {
    return this.getData(`sky.avg`);
  }
  get humidity() {
    return this.getData(`rh.avg`);
  }

  public get solrad() {
    return this.getData("solrad.avgWM2");
  }
}
