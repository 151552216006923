import Measurement from "../Measurement";
import { formatDistance } from "date-fns";
import _isNil from "lodash.isnil";
import { UnitCategory, UnitTypes, ApiProps, ApiPropGroups, Units } from "../../../types/enums";
import DataFormatter from "../../../models/aeris/observation/DataFormatter";
import { unitStore } from "../../../react/common/hooks/stores/useUnitStore";
import { pressureStore } from "../../../react/common/hooks/stores/usePressureTypeStore";
import { kmhToMs } from "../units";

const apiPropsByUnitTypes = {
  [ApiPropGroups.Temp]: {
    [UnitTypes.Metric]: ApiProps.TempMetric,
    [UnitTypes.Imperial]: ApiProps.TempImperial
  },
  [ApiPropGroups.Dewpoint]: {
    [UnitTypes.Metric]: ApiProps.DewpointMetric,
    [UnitTypes.Imperial]: ApiProps.DewpointImperial
  },
  [ApiPropGroups.Pressure]: {
    [UnitTypes.Metric]: ApiProps.PressureMetric,
    [UnitTypes.Imperial]: ApiProps.PressureImperial
  },
  [ApiPropGroups.Wind]: {
    [UnitTypes.Metric]: ApiProps.WindSpeedMetric,
    [UnitTypes.Imperial]: ApiProps.WindSpeedImperial
  },
  [ApiPropGroups.Precip]: {
    [UnitTypes.Metric]: ApiProps.PrecipMetric,
    [UnitTypes.Imperial]: ApiProps.PrecipImperial
  },
  [ApiPropGroups.FeelsLike]: {
    [UnitTypes.Metric]: ApiProps.FeelsLikeMetric,
    [UnitTypes.Imperial]: ApiProps.FeelsLikeImperial
  },
  [ApiPropGroups.Dewpoint]: {
    [UnitTypes.Metric]: ApiProps.DewpointMetric,
    [UnitTypes.Imperial]: ApiProps.DewpointImperial
  }
};

export default class Observation extends Measurement {
  public get id() {
    return this.getData("id");
  }
  public get displayId() {
    if (this.has("id")) {
      if (this.id.startsWith("PWS") || this.id.startsWith("MID") || this.id.startsWith("AUS")) {
        return this.id.slice(4);
      }
      return this.id;
    }
    return null;
  }
  public get place(): any {
    return this.getData("place") || null;
  }

  public get qcCode(): number {
    return this.getData("ob.QCcode") || 10;
  }

  public get dewpointUnits(): string {
    return this.tempUnits;
  }

  public get speedUnits(): string {
    if (unitStore["speed"] === "custom") {
      const unitChoice = unitStore.values["speed"];
      console.log("-------- ", unitChoice);
      return "kts";
    }
    return this.isMetric ? "KPH" : "MPH";
  }

  public get tempC(): number | null {
    return this.getData("ob.tempC");
  }

  public get tempF(): number | null {
    return this.getData("ob.tempF");
  }

  public get tempUnits(): string {
    const unitPref = unitStore[UnitCategory.Temp];
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref);
    return dataFormatter.units;
  }

  public get temp(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = unitPref === "metric" ? this.tempC : this.tempF;
    return value;
  }

  public get tempFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const key = apiPropsByUnitTypes[ApiPropGroups.Temp][unitPref];
    const value = this[key];
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get tempFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const key = apiPropsByUnitTypes[ApiPropGroups.Temp][unitPref];
    const value = this[key];
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  public get feelsLikeC(): number | null {
    return this.getData("ob.feelslikeC");
  }
  public get feelsLikeF(): number | null {
    return this.getData("ob.feelslikeF");
  }

  public get feelsLike(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = unitPref === "metric" ? this.feelsLikeC : this.feelsLikeF;
    return value;
  }

  public get feelsLikeFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.feelsLike;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get feelsLikeFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.feelsLike;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  public get feelsLikeUnits(): string {
    const unitPref = unitStore[UnitCategory.Temp];
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref);
    return dataFormatter.units;
  }

  public get dewpointC(): number | null {
    return this.getData("ob.dewpointC");
  }
  public get dewpointF(): number | null {
    return this.getData("ob.dewpointF");
  }
  public get dewpoint(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = unitPref === "metric" ? this.dewpointC : this.dewpointF;
    return value;
  }
  public get dewpointFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.dewpoint;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValue;
  }
  public get dewpointFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.dewpoint;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  public get [ApiProps.PressureMetric](): number | null {
    return this.getData("ob.pressureMB");
  }
  public get [ApiProps.PressureImperial](): number | null {
    return this.getData("ob.pressureIN");
  }

  public get pressure(): string | number | null {
    const unitPref = unitStore[UnitCategory.Pressure];
    const value = unitPref === "metric" ? this.pressureMb : this.pressureIn;
    return value;
  }

  public get pressureFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Pressure];
    const value = this.pressure;
    const dataFormatter = new DataFormatter(UnitCategory.Pressure, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get pressureFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Pressure];
    const value = this.pressure;
    const dataFormatter = new DataFormatter(UnitCategory.Pressure, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  public get pressureUnits(): string {
    const unitPref = unitStore[UnitCategory.Pressure];
    const dataFormatter = new DataFormatter(UnitCategory.Pressure, unitPref);
    return dataFormatter.units;
  }

  public get spressureMb(): number | null {
    return this.getData("ob.spressureMB");
  }
  public get spressureIn(): number | null {
    return this.getData("ob.spressureIN");
  }

  public get spressure(): string | number | null {
    const unitPref = unitStore[UnitCategory.Pressure];
    const value = unitPref === "metric" ? this.spressureMb : this.spressureIn;
    return value;
  }

  public get spressureFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Pressure];
    const value = this.spressure;
    const dataFormatter = new DataFormatter(UnitCategory.Pressure, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get spressureFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Pressure];
    const value = this.spressure;
    const dataFormatter = new DataFormatter(UnitCategory.Pressure, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  public get spressureUnits(): string {
    const unitPref = unitStore[UnitCategory.Pressure];
    const dataFormatter = new DataFormatter(UnitCategory.Pressure, unitPref);
    return dataFormatter.units;
  }

  public get altimeterMb(): number | null {
    return this.getData("ob.altimeterMB");
  }

  public get altimeterIn(): number | null {
    return this.getData("ob.altimeterIN");
  }

  public get altimeter(): string | number | null {
    const unitPref = unitStore[UnitCategory.Pressure];
    const value = unitPref === "metric" ? this.altimeterMb : this.altimeterIn;
    return value;
  }

  public get altimeterFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Pressure];
    const value = this.altimeter;
    const dataFormatter = new DataFormatter(UnitCategory.Pressure, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get altimeterFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Pressure];
    const value = this.altimeter;
    const dataFormatter = new DataFormatter(UnitCategory.Pressure, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  public get altimeterUnits(): string {
    const unitPref = unitStore[UnitCategory.Pressure];
    const dataFormatter = new DataFormatter(UnitCategory.Pressure, unitPref);
    return dataFormatter.units;
  }

  public get pressureByType(): number {
    const unitPref = unitStore[UnitCategory.Pressure];
    const typePref = pressureStore.type;
    if (typePref === "altimeter") {
      return unitPref === "metric" ? this.altimeterMb : this.altimeterIn;
    }
    if (typePref === "mslp") {
      return unitPref === "metric" ? this.pressureMb : this.pressureIn;
    }
    return unitPref === "metric" ? this.spressureMb : this.spressureIn;
  }

  public get precipIn(): number | null {
    return this.getData("ob.precipIN");
  }
  public get precipMm(): number | null {
    return this.getData("ob.precipMM");
  }

  public get precip(): string | number | null {
    const unitPref = unitStore[UnitCategory.Rain];
    const value = unitPref === "metric" ? this.precipMm : this.precipIn;
    return value;
  }

  public get precipFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Rain];
    const value = this.precip;
    const dataFormatter = new DataFormatter(UnitCategory.Rain, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get precipFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Rain];
    const value = this.precip;
    const dataFormatter = new DataFormatter(UnitCategory.Rain, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  public get precipUnits(): string {
    const unitPref = unitStore[UnitCategory.Rain];
    const dataFormatter = new DataFormatter(UnitCategory.Rain, unitPref);
    return dataFormatter.units;
  }

  public get precipSinceLastObIn(): string | null {
    return this.getData(`ob.precipSinceLastObIN`);
  }
  public get precipSinceLastObMm(): string | null {
    return this.getData(`ob.precipSinceLastObMM`);
  }

  public get precipSinceLastOb(): string | number | null {
    const unitPref = unitStore[UnitCategory.Rain];
    const value = unitPref === "metric" ? this.precipSinceLastObMm : this.precipSinceLastObIn;
    return value;
  }

  public get precipSinceLastObFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Rain];
    const value = this.precipSinceLastOb;
    const dataFormatter = new DataFormatter(UnitCategory.Rain, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get precipSinceLastObFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Rain];
    const value = this.precipSinceLastOb;
    const dataFormatter = new DataFormatter(UnitCategory.Rain, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  public get precipSinceMidnightIn(): string | null {
    return this.getData(`ob.precipSinceMidnightIN`);
  }
  public get precipSinceMidnightMm(): string | null {
    return this.getData(`ob.precipSinceMidnightMM`);
  }
  public get precipSinceMidnight(): string | number | null {
    const unitPref = unitStore[UnitCategory.Rain];
    const value = unitPref === "metric" ? this.precipSinceMidnightMm : this.precipSinceMidnightIn;
    return value;
  }

  public get precipSinceMidnightFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Rain];
    const value = this.precipSinceMidnight;
    const dataFormatter = new DataFormatter(UnitCategory.Rain, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get precipSinceMidnightFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Rain];
    const value = this.precipSinceMidnight;
    const dataFormatter = new DataFormatter(UnitCategory.Rain, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  public get windMph() {
    return this.getData("ob.windSpeedMPH");
  }
  public get windKph() {
    return this.getData("ob.windSpeedKPH");
  }
  public get windKts() {
    return this.getData("ob.windSpeedKTS");
  }

  public get windMps() {
    const kmh = this.windKph;
    const mps = kmhToMs(kmh);
    return mps;
  }
  public get windUnits(): string {
    const unitPref = unitStore[UnitCategory.Speed];
    const unitChoice = unitStore.values["speed"];
    if (unitChoice === "m/s") {
      return "m/s";
    }
    const dataFormatter = new DataFormatter(UnitCategory.Speed, unitPref);
    return dataFormatter.units;
  }

  public get wind(): string | number | null {
    const unitPref = unitStore[UnitCategory.Speed];
    const unitChoice = unitStore.values["speed"];
    if (unitPref === "custom") {
      if (unitChoice === "kts") {
        return this.windKts;
      } else if (unitChoice === "m/s") {
        return this.windMps;
      }
    }
    const value = unitPref === "metric" ? this.windKph : this.windMph;
    return value;
  }

  public get windFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Speed];
    const value = this.wind;
    const dataFormatter = new DataFormatter(UnitCategory.Speed, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get windFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Speed];
    const value = this.wind;
    const dataFormatter = new DataFormatter(UnitCategory.Speed, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  public get windGustMph(): string | null {
    const val = this.getData("ob.windGustMPH");
    return val === null || val === 0 ? null : val;
  }
  public get windGustKph(): string | null {
    const val = this.getData("ob.windGustKPH");
    return val === null || val === 0 ? null : val;
  }
  public get windGustKts(): string | null {
    const val = this.getData("ob.windGustKTS");
    return val === null || val === 0 ? null : val;
  }
  public get windGustMps(): string | null {
    const kmh = this.windGustKph;
    const val = kmhToMs(kmh);
    return val === null || val === 0 ? null : val;
  }

  public get windGust(): string | number | null {
    const unitPref = unitStore[UnitCategory.Speed];
    const unitChoice = unitStore.values["speed"];
    if (unitPref === "custom") {
      if (unitChoice === "kts") {
        return this.windGustKts;
      } else if (unitChoice === "m/s") {
        return this.windGustMps;
      }
    }
    const value = unitPref === "metric" ? this.windGustKph : this.windGustMph;
    return value;
  }

  public get windGustFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Speed];
    const value = this.windGust;
    const dataFormatter = new DataFormatter(UnitCategory.Speed, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get windGustFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Speed];
    const value = this.windGust;
    const dataFormatter = new DataFormatter(UnitCategory.Speed, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  private get icon(): string | null {
    return this.getData("ob.icon");
  }
  public get weatherShortCode(): string | null {
    return this.icon === null ? null : this.icon.replace(".png", "");
  }
  public get weatherPrimary(): string | null {
    return this.getData("ob.weatherPrimary");
  }

  public get date(): Date | null {
    const dateTimeIso = this.dateTimeIso;
    return dateTimeIso === null ? null : new Date(dateTimeIso);
  }
  public get dateTimeIso(): string | null {
    return this.getData("ob.dateTimeISO");
  }

  public get lat(): number | null {
    return this.getData("loc.lat");
  }
  public get lon(): number | null {
    return this.getData("loc.long");
  }
  public get latLonArray(): [number, number][] | null {
    return [this.lat, this.lon];
  }

  public get humidity(): number | null {
    return this.getData("ob.humidity");
  }

  public get humidityFormatted(): string | number | null {
    const unitPref = UnitTypes.Metric;
    const value = this.humidity;
    const dataFormatter = new DataFormatter(UnitCategory.Percent, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get humidityFormattedWithUnits(): string | number | null {
    const unitPref = UnitTypes.Metric;
    const value = this.humidity;
    const dataFormatter = new DataFormatter(UnitCategory.Percent, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  public get dateDistanceToNowInWords(): string | null {
    const currentDate = new Date();
    const observationDate = new Date(this.date);
    if (observationDate.getTime() > currentDate.getTime()) {
      return "less than a minute ago";
    }
    return this.date === null
      ? null
      : formatDistance(observationDate, currentDate, { addSuffix: true });
  }

  public get windDirection(): string | null {
    const windDir = this.getData("ob.windDir");
    if (windDir === "none") {
      return null;
    }
    if (windDir === "VRB" || windDir === -1) {
      return "VRB";
    }
    return windDir;
  }
  public get windDirectionDegrees(): number | null {
    return this.getData("ob.windDirDEG");
  }

  public get skyCover() {
    return this.getData("ob.sky");
  }

  public get solarRadiation() {
    return this.getData("ob.solradWM2");
  }
  public get uvIndex() {
    return this.getData("ob.uvi");
  }
}
