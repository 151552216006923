import React from "react";
import { defaultTheme, CircularMeter } from "pws-design-system/design-system";
import { colorForValue } from "pws-design-system/design-system/utils/colors";
import MeterProps from "./MeterProps";
import { ThemeContainer } from "../../../../../../common/hooks/useTheme";

function HumidityMeter({ observation, isMetric = false, animationDelay = 0, ...rest }: MeterProps) {
  const { theme } = ThemeContainer.useContainer();
  const value = observation.humidity;

  return (
    <CircularMeter
      variant={theme.name}
      disabled={value === null}
      label="Humidity"
      range={{ min: 0, max: 100 }}
      value={value}
      displayValue={value}
      color={["#666", colorForValue(defaultTheme.colors.weather.humidity, Number(value)) || "#666"]}
      units="%"
      animation={{ delay: animationDelay }}
      bgColor={theme.components.meter.bg}
      disabledBgColor={theme.components.meter.disabledBgColor}
      {...rest}
    />
  );
}
export default HumidityMeter;
